import { ComponentType, createElement, forwardRef, lazy } from 'react';

// export default function lazy(factory) {
// 	const Component = React.lazy(factory);
// 	Component.preload = factory;
// 	return Component;
// }

export type PreloadableComponent<T extends ComponentType<any>> = T & {
	preload: () => Promise<void>;
};

/**
 * It takes a factory function that returns a promise for a component, and returns a component that can
 * be used in place of the factory function
 * @param factory - A function that returns a promise that resolves to a component.
 * @returns A component that can be used in place of the component that is being preloaded.
 */
export default function lazyWithPreload<T extends ComponentType<any>>(
	factory: () => Promise<{ default: T }>,
): PreloadableComponent<T> {
	const LazyComponent = lazy(factory);
	let factoryPromise: Promise<void> | undefined;
	let LoadedComponent: T | undefined;

	const Component = forwardRef(function LazyWithPreload(props, ref) {
		return createElement(
			LoadedComponent ?? LazyComponent,
			Object.assign(ref ? { ref } : {}, props) as any,
		);
	}) as any as PreloadableComponent<T>;

	Component.preload = () => {
		if (!factoryPromise) {
			factoryPromise = factory().then((module) => {
				LoadedComponent = module.default;
			});
		}

		return factoryPromise;
	};
	return Component;
}
