import { CurrencyUnitSymbolMappingModel } from "types/career-services/models/CurrencyUnitMappingModel";

export default Object.freeze({
	pageSize: 20,
	daysForJobToBeNew: 2,
	shouldShowOnboarding: 'shouldShowJobOnboarding',
	salaryCurrencySymbol: '₹',
	salaryUnit: 'LPA',
	noResumeNoProfileError:
		'You cannot apply to Jobs as your Profile and Resume is not updated. Please update your Profile and Resume.',
	noResumeError:
		'You cannot apply to Jobs as your Resume is not updated. Please update your Resume.',
	noProfileError:
		'You cannot apply to Jobs as your Profile is not updated. Please update your Profile.',
	externalJobLinkMissingError:
		'This is an off-platform job and the URL is missing',
	searchPlaceholder: 'Search job title, company, skills etc.',
	locationSearchPlaceholder: 'Search city or country',
	searchTextLength: 20,
	searchTagCount: 20,
	locationTextLength: 10,
	locationSearchTagCount: 10,
	htmlEntityOfComma: '*&#44;',
	debounceTimer: 400,
	applyJobModalHeader: 'Complete your application',
	applyTooltipOnLimitExhaustion:
		'You have exhausted the application quota for this month',
	salaryFilterTooltip: 'Salary range filter is applicable only for Indian locations'
});

export enum JobPayloadKeys {
	PROFILE_FIT = 'profileFit',
	EXPERIENCE_FIT = 'experienceFit',
	SKILLS_FIT = 'primarySkillFit',
	RESUME_SELECTOR = 'resume',
}

export const SalaryRangeFilterValues = {
	min: { label: '0', value: 0 },
	max: { label: '30+', value: 31 },
}

export const CurrencyUnitSymbolMapping: CurrencyUnitSymbolMappingModel = {
	'INR': '₹',
	'EUR': '€',
	'USD': '$',
	'GBP': '£'
}

export const CurrencySalaryUnitMapping = {
	'INR': 'LPA'
}

export const SalaryTimeUnit = {
	Yearly: 'Yearly'
}