export enum Color {
	BLUE = '#45526C',
	BLUE1 = '#D6E1F8',
	DARK_BLUE = '#00008b',
	ERROR = '#eb5760',
	GREEN500C = '#02A971',
	GREY1 = '#7C8698',
	GREY2 = '#45526C',
	GREY3 = '#B4BAC4',
	GREY300 = '#F4F5F7',
	ORANGE500C = '#E77059',
	PRIMARY = '#4d8af0',
	RED = '#EF403D',
	RED500C = '#E95454',
	SUCCESS = '#22af73',
	WHITE = '#FFF',
	YELLOW500C = '#F6C24C',
	BLACK = '#000',
	GREY900 = '#1A202C',
	GREY4 = '#f4f5f7',
}

export const cohortColors = [
	'#F66B68',
	'#5097E2',
	'#1CB9C7',
	'#F7915E',
	'#34ADA3',
	'#3F6799',
	'#6458A6',
	'#2D92BA',
	'#C24C65',
	'#F7AF43',
];

export enum ColorString {
	GREEN = 'green',
	RED = 'red',
	YELLOW = 'yellow',
}

export interface CappingColor {
	chip: 'green' | 'red' | 'yellow';
	icon: Color;
}

export const CappingBreakpointsColor = [
	{ chip: ColorString.GREEN, icon: Color.GREEN500C },
	{ chip: ColorString.YELLOW, icon: Color.YELLOW500C },
	{ chip: ColorString.RED, icon: Color.ORANGE500C },
	{ chip: ColorString.RED, icon: Color.RED500C },
];
