import { RefObject, useEffect, useRef } from 'react';

/* This is a generic function that takes in an event name and a handler function. */
function useEventListener<K extends keyof WindowEventMap>(
	eventName: K,
	handler: (event: WindowEventMap[K]) => void,
): void;
/* A generic function that takes in an event name and a handler function. */
function useEventListener<
	K extends keyof HTMLElementEventMap,
	T extends HTMLElement = HTMLDivElement,
>(
	eventName: K,
	handler: (event: HTMLElementEventMap[K]) => void,
	element: RefObject<T>,
): void;

/**
 * "useEventListener is a hook that attaches an event listener to a DOM node or window."
 *
 * The first parameter is the event name. It can be a key of the WindowEventMap or HTMLElementEventMap
 * @param {KW | KH} eventName - The name of the event to listen for.
 * @param handler - A function that will be called when the event is triggered.
 * @param [element] - A ref object that stores the element that the event listener is attached to.
 */
function useEventListener<
	KW extends keyof WindowEventMap,
	KH extends keyof HTMLElementEventMap,
	T extends HTMLElement | void = void,
>(
	eventName: KW | KH,
	handler: (
		event: WindowEventMap[KW] | HTMLElementEventMap[KH] | Event,
	) => void,
	element?: RefObject<T>,
) {
	// Create a ref that stores handler
	const savedHandler = useRef<typeof handler>();

	useEffect(() => {
		// Define the listening target
		const targetElement: T | Window = element?.current || window;
		if (!(targetElement && targetElement.addEventListener)) {
			return;
		}

		// Update saved handler if necessary
		if (savedHandler.current !== handler) {
			savedHandler.current = handler;
		}

		// Create event listener that calls handler function stored in ref
		const eventListener: typeof handler = (event) => {
			// eslint-disable-next-line no-extra-boolean-cast
			if (!!savedHandler?.current) {
				savedHandler.current(event);
			}
		};

		targetElement.addEventListener(eventName, eventListener);

		// Remove event listener on cleanup
		return () => {
			targetElement.removeEventListener(eventName, eventListener);
		};
	}, [eventName, element, handler]);
}

export default useEventListener;
