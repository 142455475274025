import { message } from 'antd';

/**
 * It displays a success message.
 * @param {string} content - The content of the message.
 */
export function success(content: string) {
	message.success(content);
}

/**
 * It displays an error message.
 * @param {string} content - The content of the message.
 */
export function error(content: string) {
	message.error(content);
}

/**
 * It displays a warning message.
 * @param {string} content - The content of the message.
 */
export function warning(content: string) {
	message.warning(content);
}

/**
 * It displays a info message to the user.
 * @param {string} content - The content of the message.
 */
export function info(content: string) {
	message.info(content);
}

const obj = { success, error, warning, info };
export default obj;
