export default Object.freeze({
	options: [
		{
			label: 'Latest First',
			value: 'recent',
		},
		{
			label: 'Oldest First',
			value: 'oldest',
		},
	],
	defaultMode: 'recent',
});
