import axios from 'axios';
import { getEnv } from 'utils/env';
import {
	StandardResponseCCLaunchResponseModel,
	StandardResponseObject,
	StandardResponseCCActiveResponseModel,
	StandardResponseShowTabFirst,
	StandardApiResponseFeatureFlagsResponseDto,
} from 'types/career-services';
import { ProfileData } from 'types/custom';

const BASE_URL = getEnv('careerServicesServerUrl');
const PROFILE_PREFIX_URL = `${BASE_URL}/learner/profile`;
const CAREER_CONFIG_PREFIX_URL = `${BASE_URL}/career/config`;
const PROFILE_DATA_BASE_URL = getEnv('learnServiceBaseURL');

const URL = {
	FLAG: `${PROFILE_PREFIX_URL}/flag`,
	FIRST_TAB: `${PROFILE_PREFIX_URL}/show-tab-first`,
	CC_LAUNCH_DATA: `${PROFILE_PREFIX_URL}/cc-launch-data`,
	CC_Active: `${PROFILE_PREFIX_URL}/flag/cc-active`,
	PROFILE_DATA: `${PROFILE_DATA_BASE_URL}/profiles/:userId/deadlines`,
	HIRATION_TOKEN: `${BASE_URL}/user/resume/builder/token`,
	GET_FEATURE_FLAGS: `${CAREER_CONFIG_PREFIX_URL}/feature-flags`,
};

export default (() => {
	return {
		getCCLaunchData: () =>
			axios.get<StandardResponseCCLaunchResponseModel>(URL.CC_LAUNCH_DATA),
		getHirationToken: () => axios.get<string>(URL.HIRATION_TOKEN),
		getFlags: () => axios.get<StandardResponseObject>(URL.FLAG),
		getFirstTab: () => axios.get<StandardResponseShowTabFirst>(URL.FIRST_TAB),
		getCCActive: () =>
			axios.get<StandardResponseCCActiveResponseModel>(URL.CC_Active),
		getProfileData: (userId: number) =>
			axios.get<ProfileData>(
				URL.PROFILE_DATA.replace(':userId', userId.toString()),
				{
					headers: { sessionId: 'abc' },
				},
			),
		getFeatureFlags: () =>
			axios.get<StandardApiResponseFeatureFlagsResponseDto>(
				URL.GET_FEATURE_FLAGS,
			),
	};
})();
