// import { createAxios } from '../../utils/custom-axios';
import axios from 'axios';
import {
	BookMarkModel,
	HasAppliedModel,
	JobApplicationStatus,
	JobDetails,
	JobFiltersModel,
	JobMetadata,
	JobsRequestFilterSortModel,
	LocationModel,
	LocationRecentSearchPayload,
	PageElasticJobs,
	PageJobApplications,
	RelevanceModel,
	UserActionRequestFilterModel,
	UserJobActionModel,
	UserJobApplyModel,
	StandardApiResponseApplicationCappingResponseDto,
} from 'types/career-services';
import {
	DefaultResponse,
	MappedJobDetails,
	ResumeList,
	TopLocationsModel,
	LocationsModel,
	CountryFilterModel,
	UserProfessionalDetailsFeedbacks,
} from 'types/custom';

import { getEnv } from 'utils/env';
import { LongToast } from 'services';
import notificationMsg from './notifications-messages';
import shortToast from 'services/short-toast';
import { cloneDeep } from 'lodash-es';

const BASE_URL = getEnv('careerServicesServerUrl');
const JOB_PREFIX_URL = `${BASE_URL}/learner/jobs`;
const RESUME_BASE_URL = getEnv('learnServiceBaseURL');

const URL = {
	ACTION: `${JOB_PREFIX_URL}/user/action/`,
	ADD_LOCATION_SEARCH: `${JOB_PREFIX_URL}/add/location-search`,
	APPLICATION_STATUS: `${JOB_PREFIX_URL}/application/status/`,
	APPLIED_JOBS: `${JOB_PREFIX_URL}/application/`,
	APPLIED_OUTSIDE: `${JOB_PREFIX_URL}/user/actions/has_applied/:jobID`,
	APPLY_JOB: `${JOB_PREFIX_URL}/apply-job/:jobID`,
	BOOKMARK: `${JOB_PREFIX_URL}/user/actions/bookmark/:jobID`,
	CLICKED_OUTSIDE: `${JOB_PREFIX_URL}/user/actions/clicked_outside/:jobID`,
	FILTER_OPTION_VALUES: `${JOB_PREFIX_URL}/filter/`,
	JOB_DESCRIPTION: 'user/job/:jobID',
	JOB_DETAILS: `${JOB_PREFIX_URL}/details/v3/:jobID`,
	JOB_METADATA: `${JOB_PREFIX_URL}/metadata/`,
	JOBS: `${JOB_PREFIX_URL}/v3/`,
	RECENT_LOCATION_SEARCHES: `${JOB_PREFIX_URL}/recent/location-searches`,
	LOCATION_SUGGESTIONS: `${JOB_PREFIX_URL}/location-suggestions`,
	RECENT_SEARCHES: `${JOB_PREFIX_URL}/recent/searches/`,
	RECOMMENDED_JOBS: `${JOB_PREFIX_URL}/recommended-jobs/`,
	RELEVANCE: `${JOB_PREFIX_URL}/user/actions/relevance/:jobID`,
	RESUME_LIST: `${RESUME_BASE_URL}/profiles/:userId/resumes `,
	USER_PROFESSIONAL_DETAILS: `${RESUME_BASE_URL}/profiles/:userId/sections/Professional`,
	USER_LOCATION_DETAILS: `${RESUME_BASE_URL}/profiles/:userId/sections/Location & contact`,
	SAVED_JOBS: `${JOB_PREFIX_URL}/saved/`,
	TOP_LOCATIONS: `${JOB_PREFIX_URL}/top-locations`,
	LOCATIONS: `${JOB_PREFIX_URL}/locations`,
	COUNTRIES: `${JOB_PREFIX_URL}/countries`,
	USER_APPLICATIONS: '/user/application/:applicationID',
	CHECK_PROFILE_FIT_VISIBILITY: `${JOB_PREFIX_URL}/validate-application-count`,
	VALIDATE_APPLICATION_CAPPING: `${JOB_PREFIX_URL}/validate-application-capping`,
};

export default (() => {
	// const api = createAxios({
	//   baseURL: getEnv('careerServicesServerUrl'),
	//   name: 'learn-controller',
	// });

	const api = {
		fetchJobDetails: (jobId: number) =>
			axios.get<JobDetails>(
				URL.JOB_DETAILS.replace(':jobID', jobId.toString()),
			),
		fetchAllJobs: (payload: JobsRequestFilterSortModel) => {
			if (
				payload.filters?.salary?.range.min ||
				payload.filters?.salary?.range.max
			) {
				payload = formatSalaryFilterAsLacs(payload);
			}
			return axios.post<PageElasticJobs>(URL.JOBS, payload).catch((err) => {
				LongToast.error({
					description: notificationMsg.JOBS.ERROR_MSG,
				});
				throw new Error(err);
			});
		},
		fetchAppliedJobs: (payload: JobsRequestFilterSortModel) =>
			axios
				.post<PageJobApplications>(URL.APPLIED_JOBS, payload)
				.catch((err) => {
					LongToast.error({
						description: notificationMsg.APPLIED_JOBS.ERROR_MSG,
					});
					throw new Error(err);
				}),
		fetchRecommendedJobs: (payload: JobsRequestFilterSortModel) => {
			if (
				payload.filters?.salary?.range.min ||
				payload.filters?.salary?.range.max
			) {
				payload = formatSalaryFilterAsLacs(payload);
			}
			return axios
				.post<PageElasticJobs>(URL.RECOMMENDED_JOBS, payload)
				.catch((err) => {
					LongToast.error({
						description: notificationMsg.RECOMMENDED_JOBS.ERROR_MSG,
					});
					throw new Error(err);
				});
		},
		fetchSavedJobs: (payload: JobsRequestFilterSortModel) =>
			axios.post<PageElasticJobs>(URL.SAVED_JOBS, payload).catch((err) => {
				LongToast.error({
					description: notificationMsg.SAVED_JOBS.ERROR_MSG,
				});
				throw new Error(err);
			}),
		fetchJobsMetaData: (payload: UserActionRequestFilterModel) =>
			axios.post<JobMetadata[]>(URL.JOB_METADATA, payload),
		fetchUserAction: (payload: UserActionRequestFilterModel) =>
			axios.post<Array<UserJobActionModel>>(URL.ACTION, payload),
		fetchApplicationStatus: (payload: UserActionRequestFilterModel) =>
			axios
				.post<Array<JobApplicationStatus>>(URL.APPLICATION_STATUS, payload)
				.catch((err) => {
					LongToast.error({
						description: notificationMsg.APPLICATION_STATUS.ERROR_MSG,
					});
					throw new Error(err);
				}),
		fetchRecentGeneralSearches: () =>
			axios.get<Array<Array<string>>>(URL.RECENT_SEARCHES),
		fetchRecentLocationSearches: () =>
			axios.get<Array<string>>(URL.RECENT_LOCATION_SEARCHES).catch((err) => {
				console.log('can not fetch location recent');
				return [];
			}),
		fetchLocationSuggestions: (payload: string) =>
			axios
				.post<Array<LocationModel>>(URL.LOCATION_SUGGESTIONS, payload)
				.catch((err) => {
					console.log('can not fetch location suggestions');
					return [];
				}),
		updateRecentLocationSearch: (payload: LocationRecentSearchPayload) =>
			axios.post<string>(URL.ADD_LOCATION_SEARCH, payload),
		updateBookmark: (jobId: number, payload: BookMarkModel) =>
			axios
				.post<DefaultResponse>(
					URL.BOOKMARK.replace(':jobID', jobId.toString()),
					payload,
				)
				.catch((err) => {
					LongToast.error({
						description: payload.value
							? notificationMsg.BOOKMARK.ERROR_MSG_BOOKMARK
							: notificationMsg.BOOKMARK.ERROR_MSG_NOT_BOOKMARK,
					});
					throw err;
				}),
		updateRelevance: (jobId: number, payload: RelevanceModel) =>
			axios
				.post<DefaultResponse>(
					URL.RELEVANCE.replace(':jobID', jobId.toString()),
					payload,
				)
				.then((res) => {
					if (!res.errorCode) {
						shortToast.success(notificationMsg.RELEVANCE.SUCCESS_MSG);
					}
					return res;
				})
				.catch((err) => {
					LongToast.error({
						description: notificationMsg.RELEVANCE.ERROR_MSG,
					});
					throw err;
				}),
		fetchFiltersOptions: () =>
			axios.get<JobFiltersModel>(URL.FILTER_OPTION_VALUES),
		fetchTopLocations: () => axios.get<TopLocationsModel>(URL.TOP_LOCATIONS),
		fetchLocations: (countries: string[] = []) => {
			return axios.get<LocationsModel>(URL.LOCATIONS, {
				...(countries.length && { params: { countries: countries.join(',') } }),
			});
		},
		fetchCountries: ({ easyApply }: { easyApply: Boolean }) => {
			return axios.get<CountryFilterModel>(URL.COUNTRIES, {
				params: {
					easyApply,
				},
			});
		},
		fetchClickedOutside: (jobId: number, payload: HasAppliedModel) =>
			axios.post<DefaultResponse>(
				URL.CLICKED_OUTSIDE.replace(':jobID', jobId.toString()),
				payload,
			),
		fetchAppliedOutside: (jobId: number, payload: HasAppliedModel) =>
			axios.post<DefaultResponse>(
				URL.APPLIED_OUTSIDE.replace(':jobID', jobId.toString()),
				payload,
			),
		fetchResumeList: (userId: number) =>
			axios.get<ResumeList>(
				URL.RESUME_LIST.replace(':userId', userId.toString()),
				{
					headers: { sessionId: 'abc' },
				},
			),
		fetchUserProfessionalDetails: (userId: number) =>
			axios.get<UserProfessionalDetailsFeedbacks>(
				URL.USER_PROFESSIONAL_DETAILS.replace(':userId', userId.toString()),
			),
		fetchUserLocationDetails: (userId: number) =>
			axios.get<UserProfessionalDetailsFeedbacks>(
				URL.USER_LOCATION_DETAILS.replace(':userId', userId.toString()),
			),
		applyJob: (jobId: number, payload: UserJobApplyModel) =>
			axios
				.post<string>(
					URL.APPLY_JOB.replace(':jobID', jobId.toString()),
					payload,
				)
				.then((res) => {
					shortToast.success(notificationMsg.APPLY_JOB.SUCCESS_MSG);
					return res;
				})
				.catch((err) => {
					LongToast.error({
						description: notificationMsg.APPLY_JOB.ERROR_MSG,
					});
					throw new Error(err);
				}),
		checkProfileFitVisibility: () =>
			axios.get<boolean>(URL.CHECK_PROFILE_FIT_VISIBILITY),
		validateApplicationCapping: () =>
			axios.get<StandardApiResponseApplicationCappingResponseDto>(
				URL.VALIDATE_APPLICATION_CAPPING,
			),
		// ********** Custom ***************
		fetchCompleteJobDetails: async (
			jobId: number,
		): Promise<MappedJobDetails> => {
			try {
				const response = await Promise.all([
					api.fetchJobDetails(jobId),
					api.fetchJobsMetaData({ ids: [jobId] }),
					api.fetchUserAction({ ids: [jobId] }),
				]);
				const [jobDetails, [meta], [actions]] = response;
				return { ...jobDetails, meta: meta, userAction: actions };
			} catch (err) {
				LongToast.error({
					description: notificationMsg.JOB_DETAILS.ERROR_MSG,
				});
				throw new Error(err as string);
			}
		},
		fetchMetaAndActions: async (jobIds: Array<number>) => {
			const [metaResponse, actionsResponse] = await Promise.allSettled([
				api.fetchJobsMetaData({ ids: jobIds }),
				api.fetchUserAction({ ids: jobIds }),
			]);
			const meta =
				metaResponse.status === 'fulfilled' ? metaResponse.value : [];
			const actions =
				actionsResponse.status === 'fulfilled' ? actionsResponse.value : [];
			return { metaList: meta, userActionList: actions };
		},
		fetchMetaAndStatuses: async (
			jobIds: number[],
			applicationIds: number[],
		) => {
			const [metaResponse, statusResponse] = await Promise.allSettled([
				api.fetchJobsMetaData({ ids: jobIds }),
				api.fetchApplicationStatus({ ids: applicationIds }),
			]);
			const metaList =
				metaResponse.status === 'fulfilled' ? metaResponse.value : [];
			const statusList =
				statusResponse.status === 'fulfilled' ? statusResponse.value : [];
			return { metaList, statusList };
		},

		fetchAllFilterOptions: async () => {
			const [filterResponse, locationsResponse, countriesResponse] =
				await Promise.allSettled([
					api.fetchFiltersOptions(),
					api.fetchLocations([]),
					api.fetchCountries({ easyApply: true }),
				]);

			const filterList: any =
				filterResponse.status === 'fulfilled' ? filterResponse.value : [];
			const locationsList: LocationsModel =
				locationsResponse.status === 'fulfilled' ? locationsResponse.value : [];
			const countriesList: CountryFilterModel =
				countriesResponse.status === 'fulfilled' ? countriesResponse.value : [];

			return { filterList, locationsList, countriesList };
		},
	};

	return api;
})();

export const formatLocationsFilterData = (
	locationsData: Record<string, Array<string>>,
) => {
	let isRemoteLocationPresent = false;
	let locationOptions = [];
	for (let country in locationsData) {
		locationOptions.push(...locationsData[country]);
	}
	locationOptions = locationOptions.filter((location) => {
		if (location.toLowerCase() === 'remote') {
			isRemoteLocationPresent = true;
			return false;
		} else {
			return true;
		}
	});
	isRemoteLocationPresent && locationOptions.unshift('Remote');
	return locationOptions;
};

const formatSalaryFilterAsLacs = (payload: JobsRequestFilterSortModel) => {
	let temp = cloneDeep(payload);
	if (temp.filters?.salary?.range.min) {
		temp.filters.salary.range.min = temp.filters.salary.range.min * 100000;
	}
	if (temp.filters?.salary?.range.max) {
		temp.filters.salary.range.max = temp.filters.salary.range.max * 100000;
	}
	return temp;
};
