import { Action, action, Thunk, thunk } from 'easy-peasy';

import api from './service';
import { SelfEnrollPayload } from 'types/custom';

export interface CourseStore {
	isSelfEnrolled: boolean;
	setIsSelfEnrolled: Action<CourseStore>;
	resetIsSelfEnrolled: Action<CourseStore>;
	reset: Action<CourseStore>;
	selfEnroll: Thunk<CourseStore, SelfEnrollPayload>;
	fetchCalenderCohort: Thunk<CourseStore, string>;
}

const course: CourseStore = {
	// ********** Data ***************
	isSelfEnrolled: false,
	// ********** Actions ***************
	setIsSelfEnrolled: action((state) => {
		state.isSelfEnrolled = true;
	}),
	resetIsSelfEnrolled: action((state) => {
		state.isSelfEnrolled = false;
	}),
	reset: action((state, payload) => {
		state.isSelfEnrolled = false;
	}),
	// ********** Thunks ***************
	selfEnroll: thunk(async (actions, payload) => {
		await api.selfEnroll(payload);
		actions.setIsSelfEnrolled();
	}),
	fetchCalenderCohort: thunk(async (actions, payload) => {
		return api.fetchCalenderCohort(payload);
	}),
};

export default course;
