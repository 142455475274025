import platform from 'platform';
import store from 'store';
import { User } from 'types/career-services';
import { getCookie } from 'utils/cookies';
import { cookies, routes } from '../constants';
import Logger from './logger';

const logger = new Logger({ fileName: 'trackevents' });

declare global {
	interface Window {
		clevertap: {
			event: Array<any>;
			profile: Array<{
				Site: {
					Name: string;
					Email: string;
				};
			}>;
			account: Array<{
				id: string;
			}>;
			onUserLogin: Array<any>;
			notifications: Array<any>;
			privacy: Array<any>;
		};
		__insp: {
			push: (t: [string, string | object]) => void;
		};
	}
}

interface BaseProperties {
	user_id?: number;
	email?: string;
	devicetype: 'mobile' | 'web';
	width: string;
	height: string;
	browser?: string;
	browser_version?: string;
	device_manufacturer?: string;
	device_model?: string;
	device_os?: string;
	device_os_version?: string;
	cohort_id?: string;
	app_session?: string;
	utm_source?: string;
	utm_campaign?: string;
	utm_medium?: string;
	logged_in_flag: boolean;
}

class TrackEvents {
	static baseProperties: null | BaseProperties = null;
	static dueEvents: Array<any> = [];

	/**
	 * If the clevertap object is defined in the window object, then push a profile object to the clevertap
	 * @param {string} name - The name of the user
	 * @param {string} email - The email of the user
	 */
	static setupProfile(name: string, email: string) {
		// If the clevertap object is defined in the window object, then push a profile object to the clevertap
		if ('clevertap' in window) {
			window.clevertap.profile.push({
				Site: {
					Name: name,
					Email: email,
				},
			});
		}
		// If the insp object is defined in the window object, then push a profile object to the inspectlet
		if ('__insp' in window) {
			window?.__insp.push(['identify', email]);
			window?.__insp.push(['tagSession', { email: email, name: name }]);
		}
	}

	/**
	 * It sets up the base properties for the event
	 * @param {User} user - User - the user object
	 */
	static setupBaseProperties(user: User) {
		const params = new URLSearchParams(window.location.search);
		this.baseProperties = {
			...this.baseProperties,
			devicetype: window.innerWidth >= 768 ? 'web' : 'mobile', // TODO: breakpoint constant
			width: `${window.innerWidth}px`,
			height: `${window.innerHeight}px`,
			browser: platform.name, // 'Safari'
			browser_version: platform.version, // '5.1'
			device_manufacturer: platform.manufacturer, // 'Apple'
			device_model: platform.product, // 'iPad'
			device_os: platform?.os?.family,
			device_os_version: platform?.os?.version,
			user_id: user.id,
			email: user.email,
			cohort_id: getCookie(cookies.COURSE_ID_COOKIE),
			app_session: getCookie(cookies.SESSION_ID_COOKIE),
			utm_source: params.get('utm_source')||'',
			utm_campaign: params.get('utm_campaign')||'',
			utm_medium: params.get('utm_medium')||'',
			logged_in_flag: !!getCookie(cookies.AUTH_TOKEN_COOKIE),
		};
	}

	/**
	 * It takes all the events that were queued up and logs them
	 */
	static executeDueEvents() {
		TrackEvents.dueEvents.forEach((item) => {
			this.log(item.type, item.data);
		});
		TrackEvents.dueEvents = [];
	}

	/**
	 * It sets up the user profile and base properties for the user, and then executes any events that were
	 * queued up before the user profile was set up
	 */
	static triggerIntialEvents() {
		const { learnerController } = store.getState();
		const { launchData } = learnerController;

		if (launchData) {
			const { data } = launchData;
			if (data?.user?.id && data?.user?.name && data.user.email) {
				TrackEvents.setupProfile(data.user.name, data.user.email);
				TrackEvents.setupBaseProperties(data.user);
				TrackEvents.executeDueEvents();
			}
		}
	}
	/**
	 * It logs the event to the logger and to the CleverTap SDK
	 * @param {any} type - The name of the event you want to track.
	 * @param {any} data - This is the data that you want to send to the analytics platform.
	 */
	static log(type: any, data: any = {}) {
		const { pageCategory, pageTitle } = getCurrentPage() || {};
		if (this.baseProperties) {
			const properties = {
				...data,
				...this.baseProperties,
				page_title: pageTitle,
				page_category: pageCategory,
				page_url: window.location.href,
			};
			logger.log(type, { ...properties });
			window.clevertap.event.push(type, { ...properties });
		} else {
			this.dueEvents.push({ type, data });
			getCookie(cookies.COURSE_ID_COOKIE) && TrackEvents.triggerIntialEvents();
		}
	}
}

/**
 * Given a list of routes, return the route that matches the current pathname
 * @returns The current page object.
 */
function getCurrentPage() {
	const pathname = window.location.pathname;
	return routes.find((route: any) => pathname.startsWith(route.matchPath));
}

export default TrackEvents;
