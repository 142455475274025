import { RefObject, useState } from 'react';

import useEventListener from './useEventListener';

/**
 * Use the ref to set a state to true when the mouse enters the element, and set the state to false
 * when the mouse leaves the element.
 * @param elementRef - The reference object when having entered via mouse click
 * @returns A boolean value.
 */
function useHover<T extends HTMLElement = HTMLElement>(
	elementRef: RefObject<T>,
): boolean {
	const [value, setValue] = useState<boolean>(false);

	const handleMouseEnter = () => setValue(true);
	const handleMouseLeave = () => setValue(false);

	useEventListener('mouseenter', handleMouseEnter, elementRef);
	useEventListener('mouseleave', handleMouseLeave, elementRef);

	return value;
}

export default useHover;
