import React from 'react';

// import styles from'./index.module.scss';

interface FlexProps {
	children?: any;
	className?: string;
	container?: boolean;
	/****** Container Props ********/
	flexDirection?: 'row' | 'column';
	gap?: string;
	justifyContent?:
		| 'flex-start'
		| 'flex-end'
		| 'center'
		| 'space-between'
		| 'space-around'
		| 'initial'
		| 'inherit';
	flexWrap?: 'wrap' | 'nowrap' | 'wrap-reverse';
	alignItems?:
		| 'stretch'
		| 'center'
		| 'flex-start'
		| 'flex-end'
		| 'baseline'
		| 'initial'
		| 'inherit';
	/****** Child Props ********/
	flexGrow?: number;
	flexShrink?: number;
	flexBasis?: number;
	flex?: string;
	alignSelf?:
	| 'stretch'
	| 'center'
	| 'flex-start'
	| 'flex-end'
	| 'baseline'
	| 'self-end'
	| 'self-start';
	/****** Common Layout Props ********/
	padding?: string;
	margin?: string;
	width?: string;
	height?: string;
	maxWidth?: string;
	maxHeight?: string;
}
const Flex: React.FC<FlexProps> = (props) => {
	const { container = true } = props;
	return (
		<div
			className={props.className}
			style={{
				display: container ? 'flex' : 'block',
				justifyContent: props.justifyContent || 'flex-start',
				flexDirection: props.flexDirection || 'row',
				gap: props.gap || 0,
				flexGrow: props.flexGrow || 0,
				flexBasis: props.flexBasis || 'auto',
				flexShrink: props.flexShrink || 1,
				flexWrap: props.flexWrap || 'nowrap',
				flex: props.flex || '0 1 auto',
				alignItems: props.alignItems || 'stretch',
				margin: props.margin || '0',
				padding: props.padding || '0',
				width: props.width || 'auto',
				height: props.height || 'auto',
				maxWidth: props.maxWidth || 'none',
				alignSelf: props.alignSelf || 'auto'
			}}
		>
			{props.children}
		</div>
	);
};
export default Flex;
