import { reduce } from 'lodash-es';
import convertStringToNumber from './convertStringToNumber';

interface Feedback {
	questionIdentifier: string;
	answer: string;
}

interface Details {
	noticePeriod?: string;
	totalExp?: string;
	currentTitle?: string;
	currentEmployer?: string;
	currentSalary?: number;
	cityName?: string;
}

/**
 * It extracts user professional details and return values in object form
 * @param {Feedback[]} feedbacks - feedbacks array
 * @returns {Details} Details object.
 */
export default function extractUserProfessionalDetails(
	feedbacks: Feedback[] = [],
): Details {
	return reduce(
		feedbacks,
		(acc: Details, feedback: Feedback) => {
			const { questionIdentifier, answer = '' } = feedback;

			switch (questionIdentifier) {
				case 'noticePeriod':
					acc.noticePeriod = answer;
					break;
				case 'ctc':
					acc.currentSalary = convertStringToNumber(answer);
					break;
				case 'totalWorkExp':
					acc.totalExp = answer;
					break;
				case 'designation':
					acc.currentTitle = answer;
					break;
				case 'org':
					acc.currentEmployer = answer;
					break;
				case 'city':
					acc.cityName = answer;
					break;
			}

			return acc;
		},
		{} as Details,
	);
}
