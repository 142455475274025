import { User as AuthUser } from '../types/auth-services';
import { User as CareerUser } from '../types/career-services';
import { CustomUser } from '../types/custom/models/User';

/**
 * Convert a user object to a custom user object
 * @param {AuthUser | CareerUser} user - The user can be a AuthUser or a user with careers access
 * @returns A custom user object.
 */
export function convertUserToCustomUser(
	user: AuthUser | CareerUser,
): CustomUser {
	const {
		id,
		name,
		firstname,
		lastname,
		phoneNumber,
		profilePic,
		rolesStringSet,
		email,
		username,
		paidUser,
		highestRole,
	} = user;

	return {
		id: id || 0,
		name: name || '',
		firstname: firstname || '',
		lastname: lastname || '',
		phoneNumber: phoneNumber || '',
		username: username || '',
		email: email || '',
		rolesStringSet: rolesStringSet || [],
		paidUser: paidUser || false,
		highestRole: highestRole || '',
		profilePic: profilePic || { url: '' },
	};
}
