import { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';
import { cookies, apiHeaders } from '../constants';
import { LongToast, TrackEvents } from 'services';
import Logger from './logger';

const { default: axios } = require('axios');
const { getCookie } = require('utils/cookies');
const { AUTH_TOKEN_COOKIE } = cookies;
const { AUTH_TOKEN_HEADER } = apiHeaders;
const logger = new Logger({ fileName: 'axios' });

axios.interceptors.request.use(
	/* This is a request interceptor. It is used to add headers to the request. */
	function (config: AxiosRequestConfig) {
		// Do something before request is sent
		const token = getCookie(AUTH_TOKEN_COOKIE);
		const headers = config.headers;
		if (token && headers) {
			headers[AUTH_TOKEN_HEADER] = token;
		}
		return config;
	},
	/* A request interceptor. It is used to handle any error that occurs during the request. */
	function (error: AxiosError) {
		// Do something with request error
		return Promise.reject(error);
	},
);

axios.interceptors.response.use(
	(response: AxiosResponse) => {
		const resp = response.data;
		logger.log('resp response===>', resp, response);
		if (resp?.errorCode) {
			// TODO: once backend fixies these API responses the below condition can be removed
			if (
				response.config.url?.includes('clicked_outside') ||
				response.config.url?.includes('has_applied') ||
				response.config.url?.includes('cc-launch-data')
			) {
				return resp;
			}
			const errorMsg = resp?.message;
			errorMsg &&
				LongToast.error({
					title: 'Something went wrong',
					description: errorMsg,
				});
		}
		return resp;
	},
	(error: AxiosError) => {
		// let { headers } = error.config;
		// NOTE: retry mechanism
		// if (headers?.retry > 0) {
		//   headers = { ...headers, retry: headers?.retry - 1 };
		//   error.config.headers = headers;
		//   return new Promise((resolve) =>
		//     setTimeout(() => {
		//       resolve(axios.request(error.config));
		//     }, headers?.retryDelay)
		//   );
		// }

		const errorInfoObject = {
			endpoint: error?.response?.request?.responseURL,
			status_code: error?.response?.status,
			error_message: error?.response?.data.message,
		};

		TrackEvents.log('events_error_raised', errorInfoObject);
		logger.logError('inside error===>', error);
		// NOTE: skip global error handling condition
		// example: axios.get('/user/1', {errorHandle: false})
		if (
			error.config.hasOwnProperty('errorHandle') &&
			(error?.config as any)?.errorHandle === false
		) {
			return Promise.reject(error.response?.data);
		}

		// NOTE: handle global errors
		// TODO: check with backend team if errorLits is the only key for getting error messages
		// let errorMsg: string = 'Something went wrong';
		// errorMsg = error?.message || errorMsg;
		// if (error?.response) {
		// 	const errorObj = error?.response?.data?.errorList?.[0];
		// 	errorMsg = errorObj?.errorMessage;
		// }

		// LongToast.error({
		// 	title: 'Error',
		// 	description: errorMsg,
		// });

		if (error?.response?.status === 401) {
			// TODO: handle any 401 Unauthorized error here
		}
		if (error?.response?.status === 403) {
			// TODO: handle any 403 Forbidden error here
			// This comes when user is not allowed to perform the operation
		}
		return Promise.reject(error.response?.data);
	},
);
