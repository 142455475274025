import { cloneDeep } from 'lodash-es';
// import { TypedObject } from '../types/custom';

// TODO: fix TS errors
// export function renameKeys<T>(
//   originalObj: T,
//   keysMap: TypedObject<string>,
//   mutate = true
// ) {
//   const obj = mutate ? originalObj : cloneDeep(originalObj);

//   for (const [oldKey, newKey] of Object.entries(keysMap)) {
//     obj[newKey] = obj[oldKey];
//     delete obj[oldKey];
//   }
//   return obj;
// }

// export function renameKeysInArrayOfObj<T>(
//   arr: Array<T>,
//   keysMap: TypedObject<string>,
//   mutateObject = true
// ) {
//   return arr.map((obj) => renameKeys<T>(obj, keysMap, mutateObject));
// }

// function ArrayToObj(arr: Array<object>, key: string): object {
// 	let newObj: { [key: string]: object } = {};
// 	arr.forEach(value => {
// 		if (key in value) {
// 			newObj[value[key]] = value;
// 		}
// 	});
// 	return newObj;
// }

/**
 * Given an array of objects, create a new object where the key is the value of the property specified
 * by the key parameter
 * @returns An object with the key being the value of the key passed in and the value being the value
 * of the object.
 */
function ArrayToObj(arr: Array<object>, key) {
	let newObj = {};
	arr.forEach((value) => {
		if (key in value) {
			newObj[value[key]] = cloneDeep(value);
		}
	});
	return newObj;
}

export { ArrayToObj };
