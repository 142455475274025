import { Filters } from 'types/custom';
import { FilerTypeEnum, JobFiltersKeysEnum } from 'constants/filters';
import { JobsRequestFilterSortModel } from 'types/career-services';
import { jobSortOptions, DeviceType } from '../../../constants';
import jobsConstants, { SalaryRangeFilterValues } from 'constants/jobs-constants';

export const DEFAULT_QUERY_PAYLOAD: JobsRequestFilterSortModel = {
	sortBy: 'recent',
	pagination: { pageNumber: 0, pageSize: 20 },
	filters: {
		easyApply: true,
		internship: false,
		fresherJob: false,
		fields: [],
		countries: [],
		locations: [],
		experience: {
			total: { min: undefined, max: undefined },
			relevant: { min: undefined, max: undefined },
		},
		salary: {
			range: {
				min: undefined,
				max: undefined,
			}
		},
	},
	searchKeywords: [],
	locationSearchKeywords: [],
};

export const FILTERS_DATA: Filters = [
	// TODO: DEL-948: Disabling based on this ticket
	// {
	// 	key: JobFiltersKeysEnum.EasyApply,
	// 	label: 'upGrad Easy Apply only',
	// 	isExpanded: true,
	// 	type: FilerTypeEnum.TOGGLE,
	// 	defaultValue: true,
	// 	headerClassName: ['collapseHeader'],
	// 	devices: [DeviceType.DESKTOP, DeviceType.MOBILE, DeviceType.TABLET],
	// },
	{
		key: JobFiltersKeysEnum.FresherJob,
		label: 'Fresher Job Only',
		isExpanded: true,
		type: FilerTypeEnum.TOGGLE,
		defaultValue: false,
		headerClassName: ['collapseHeader', 'collapseHeaderToRight'],
		devices: [DeviceType.DESKTOP, DeviceType.MOBILE, DeviceType.TABLET],
	},
	{
		key: JobFiltersKeysEnum.Internship,
		label: 'Internship Only',
		isExpanded: true,
		type: FilerTypeEnum.TOGGLE,
		defaultValue: false,
		headerClassName: ['collapseHeader', 'collapseHeaderToRight'],
		devices: [DeviceType.DESKTOP, DeviceType.MOBILE, DeviceType.TABLET],
	},
	{
		key: JobFiltersKeysEnum.SortBy,
		label: 'Sort By',
		isExpanded: false,
		type: FilerTypeEnum.RADIO,
		options: jobSortOptions.options,
		defaultValue: jobSortOptions.defaultMode,
		headerClassName: ['collapseHeader'],
		devices: [DeviceType.MOBILE, DeviceType.TABLET],
	},
	{
		key: JobFiltersKeysEnum.Field,
		label: 'Domain',
		isExpanded: false,
		type: FilerTypeEnum.CHECKBOX,
		options: [],
		defaultValue: [],
		headerClassName: ['collapseHeader'],
		devices: [DeviceType.DESKTOP, DeviceType.MOBILE, DeviceType.TABLET],
	},
	{
		key: JobFiltersKeysEnum.Experience,
		label: 'Experience',
		isExpanded: false,
		type: null,
		headerClassName: ['collapseHeader'],
		devices: [DeviceType.DESKTOP, DeviceType.MOBILE, DeviceType.TABLET],
		children: [
			{
				key: JobFiltersKeysEnum.TotalExperience,
				label: 'Total Experience (in years)',
				isExpanded: true,
				type: FilerTypeEnum.RANGE,
				min: { label: '0', value: 0 },
				max: { label: '30+', value: 31 },
				step: 1,
				defaultValue: { min: undefined, max: undefined },
				tooltipSuffix: 'yrs',
				headerClassName: ['collapseChildHeader'],
				devices: [DeviceType.DESKTOP, DeviceType.MOBILE, DeviceType.TABLET],
			},
			{
				key: JobFiltersKeysEnum.RelevantExperience,
				label: 'Relevant Experience (in years)',
				isExpanded: true,
				type: FilerTypeEnum.RANGE,
				min: { label: '0', value: 0 },
				max: { label: '30+', value: 31 },
				step: 1,
				defaultValue: { min: undefined, max: undefined },
				tooltipSuffix: 'yrs',
				headerClassName: ['collapseChildHeader'],
				devices: [DeviceType.DESKTOP, DeviceType.MOBILE, DeviceType.TABLET],
			},
		],
	},
	{
		key: JobFiltersKeysEnum.Country,
		label: 'Country',
		isExpanded: false,
		type: FilerTypeEnum.CHECKBOX,
		options: [],
		defaultValue: [],
		headerClassName: ['collapseHeader'],
		devices: [DeviceType.DESKTOP, DeviceType.MOBILE, DeviceType.TABLET],
	},
	{
		key: JobFiltersKeysEnum.Location,
		label: 'Location',
		isExpanded: false,
		type: FilerTypeEnum.CHECKBOX,
		options: [],
		defaultValue: [],
		headerClassName: ['collapseHeader'],
		devices: [DeviceType.DESKTOP, DeviceType.MOBILE, DeviceType.TABLET],
	},
	{
		key: JobFiltersKeysEnum.Salary,
		label: 'Salary',
		isExpanded: false,
		type: null,
		headerClassName: ['collapseHeader'],
		infoIconText: jobsConstants.salaryFilterTooltip,
		devices: [DeviceType.DESKTOP, DeviceType.MOBILE, DeviceType.TABLET],
		children: [
			{
				key: JobFiltersKeysEnum.SalaryRange,
				label: 'Salary Range (per annum)',
				isExpanded: true,
				type: FilerTypeEnum.RANGE,
				...SalaryRangeFilterValues,
				step: 1,
				defaultValue: { min: undefined, max: undefined },
				tooltipSuffix: 'LPA',
				headerClassName: ['collapseChildHeader'],
				devices: [DeviceType.DESKTOP, DeviceType.MOBILE, DeviceType.TABLET],
			},
		],
	},
];
