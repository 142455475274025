import {
	Action,
	action,
	computed,
	Computed,
	Thunk,
	thunk,
	thunkOn,
	ThunkOn,
} from 'easy-peasy';
import { StoreModel } from 'store';

import {
	StandardResponseCCLaunchResponseModel,
	StandardResponseShowTabFirst,
	Cohort,
	FeatureFlagsResponseDto,
} from 'types/career-services';
import api from './service';
import { LongToast, TrackEvents } from 'services';
import { getCookie, setCookie } from 'utils/cookies';
import { cookies, FeatureFlags, profileSections } from '../../constants';
import { ProfileData } from 'types/custom';

export interface LearnerControllerStore {
	// ********** Data ***************
	launchData: StandardResponseCCLaunchResponseModel | null;
	firstTab: StandardResponseShowTabFirst.data | null;
	profileData: ProfileData | null;
	isProfileComplete: Computed<LearnerControllerStore, boolean | null>;
	featureFlags:
		| {
				[key in FeatureFlags]: boolean;
		  }
		| {};
	// ********** Actions ***************
	setLaunchData: Action<
		LearnerControllerStore,
		StandardResponseCCLaunchResponseModel
	>;
	resetLaunchData: Action<LearnerControllerStore>;
	selectedCohort: Cohort | null;
	setFirstTab: Action<
		LearnerControllerStore,
		StandardResponseShowTabFirst.data
	>;
	resetFirstTab: Action<LearnerControllerStore>;
	reset: Action<LearnerControllerStore>;
	setSelectedCohort: Action<LearnerControllerStore, Cohort>;
	resetSelectedCohort: Action<LearnerControllerStore>;
	setProfileData: Action<LearnerControllerStore, ProfileData>;
	resetProfileData: Action<LearnerControllerStore>;
	setFeatureFlags: Action<LearnerControllerStore, FeatureFlagsResponseDto>;
	// ********** Thunks ***************
	getLaunchData: Thunk<LearnerControllerStore, undefined, StoreModel>;
	getFirstTab: Thunk<LearnerControllerStore, undefined>;
	getProfileData: Thunk<LearnerControllerStore, number>;
	getHirationToken: Thunk<LearnerControllerStore, undefined>;
	getFeatureFlags: Thunk<LearnerControllerStore>;
	// ********** Listeners ***************
	onSetCCLaunchData: ThunkOn<LearnerControllerStore, any, StoreModel>;
	onUserReset: ThunkOn<LearnerControllerStore, any, StoreModel>;
}

const learnerController: LearnerControllerStore = {
	// ********** Data ***************
	launchData: null,
	firstTab: null,
	selectedCohort: null,
	profileData: null,
	isProfileComplete: computed((state) => {
		if (state.profileData === null) return null;
		return state.profileData.every(
			(data) => data.questions?.totalCount === data.questions?.completedCount,
		);
	}),
	featureFlags: {},
	// ********** Actions ***************
	reset: action((state, payload) => {
		state.launchData = null;
		state.firstTab = null;
		state.selectedCohort = null;
		state.profileData = null;
		state.featureFlags = {};
	}),
	setLaunchData: action((state, payload) => {
		state.launchData = payload;
	}),
	resetLaunchData: action((state, payload) => {
		state.launchData = null;
	}),
	setFirstTab: action((state, payload) => {
		state.firstTab = payload;
	}),
	resetFirstTab: action((state, payload) => {
		state.firstTab = null;
	}),
	setSelectedCohort: action((state, payload) => {
		state.selectedCohort = payload;
	}),
	resetSelectedCohort: action((state, payload) => {
		state.selectedCohort = null;
	}),
	setProfileData: action((state, payload) => {
		state.profileData = payload;
	}),
	resetProfileData: action((state, payload) => {
		state.profileData = null;
	}),
	setFeatureFlags: action((state, payload) => {
		state.featureFlags = payload.featureFlags || {};
	}),
	// ********** Thunks ***************
	getLaunchData: thunk(async (actions, payload, { getStoreActions }) => {
		// const { data: launchData } = await api.getCCLaunchData();
		try {
			const launchData = await api.getCCLaunchData();
			if (!launchData.errorCode) {
				actions.setLaunchData(launchData);
			} else if (launchData.message === 'User details missing') {
				throw Error('User details are not found');
			} else {
				throw Error('User details are corrupted');
			}
		} catch (err: any) {
			if (err?.error === 'Unauthorized' && err.status === 401) {
				LongToast.error({
					title: 'Error! Bad Credentials',
					description: 'User is not Authorized',
				});
			} else {
				LongToast.error({
					title: 'Error! Bad Credentials',
					description: err.message || 'Unable to Fetch user details',
				});
			}
			throw err;
		}
	}),
	getFirstTab: thunk(async (actions, payload) => {
		const response = await api.getFirstTab();
		const firstTab = response.data;
		firstTab && actions.setFirstTab(firstTab);
	}),
	getProfileData: thunk(async (actions, payload) => {
		const userId = payload;
		if (userId) {
			const profileData = await api.getProfileData(userId);
			const usedProfileSection = profileData.filter(
				(profile) => profile.name && profileSections.includes(profile.name),
			);

			actions.setProfileData(usedProfileSection);
		}
	}),
	getHirationToken: thunk(async (actions) => {
		try {
			const hirationToken = await api.getHirationToken();
			setCookie({ [cookies.HIRATION_AUTH]: hirationToken });
		} catch (error) {
			console.error('Unable to fetch Hiration Token', error);
		}
	}),
	getFeatureFlags: thunk(async (actions) => {
		const response = await api.getFeatureFlags();
		if (response?.data) {
			actions.setFeatureFlags(response.data);
		}
	}),
	// ********** Listeners ***************
	onSetCCLaunchData: thunkOn(
		(actions) => actions.setLaunchData,
		async (actions, target) => {
			await actions.getFirstTab();
			// getCookie(cookies.COURSE_ID_COOKIE) && TrackEvents.triggerIntialEvents();
			TrackEvents.triggerIntialEvents();
			// call hiration API if hiration cookie is not present
			!getCookie(cookies.HIRATION_AUTH) && (await actions.getHirationToken());
			actions.getFeatureFlags();

			// To call API for Profile data
			const { payload } = target;
			const { data } = payload;
			if (data && data.user && data.user.id) {
				actions.getProfileData(data.user.id);
			}
		},
	),

	onUserReset: thunkOn(
		(actions, storeActions) => storeActions.user.reset,
		async (actions, target) => {
			await actions.reset();
		},
	),
};

export default learnerController;
