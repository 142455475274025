// import { createAxios } from '../../utils/custom-axios';
import axios from 'axios';

import { getEnv } from 'utils/env';
import { SelfEnrollPayload } from 'types/custom';

const BASE_URL = getEnv('courseServiceBaseUrl');
const LEARN_URL = getEnv('courseServiceBaseUrl');
const URL = {
	SELF_ENROLL: `${BASE_URL}/v3/enrollments/selfEnroll`,
	CALENDER_COHORT: `${LEARN_URL}/v2/courseconfigurations?courseId=:cohortId`,
};

export default (() => {
	return {
		fetchCalenderCohort: (cohortId: string) =>
			axios.get<any>(URL.CALENDER_COHORT.replace(':cohortId', cohortId)),
		selfEnroll: (payload: SelfEnrollPayload) =>
			axios.post<any>(URL.SELF_ENROLL, payload),
	};
})();
