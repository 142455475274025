import { getEnv } from './env';
import { cookies } from 'constants/index';

/* Setting the domain of the cookie. */
const domainString =
	process.env.NODE_ENV === 'development'
		? ''
		: `domain=${getEnv('cookieDomain')}`;

/**
 * It takes an object and sets each key/value pair as a cookie
 * @param {any} obj - The object containing the key/value pairs to be set as cookies.
 * @param [expiry=null] - The expiry date of the cookie. If not set, the cookie will expire when the
 * browser is closed.
 */
const setCookie = (obj: any, expiry = null) => {
	Object.keys(obj).forEach((key) => {
		const expirySetting = expiry ? `; cookie=ok;expires=${expiry}` : '';
		document.cookie = `${key}=${obj[key]}; path=/; ${domainString}${expirySetting}`;
	});
};

/**
 * Set a cookie with a key, value, and expiry date.
 * @param {string} key - The name of the cookie
 * @param {string} value - The value of the cookie.
 * @param {number} days - number - The number of days before the cookie expires.
 */
const setCookieWithExpiry = (key: string, value: string, days: number) => {
	const date = new Date();
	const daysBeforeExpiry = days || 365;
	date.setTime(date.getTime() + daysBeforeExpiry * 86400000);
	document.cookie = `${key}=${value}; path=/;expires=${date.toUTCString()}; ${domainString}`;
};

/**
 * Get the value of a cookie by name.
 * @param {string} name - The name of the cookie you want to get.
 * @returns The value of the cookie with the name passed in.
 */
const getCookie = (name: string) => {
	const value = `; ${document.cookie}`;
	const parts = value.split(`; ${name}=`);
	if (parts.length === 2) {
		return parts.pop()?.split(';').shift();
	}
	return '';
};

/**
 * Deletes a cookie
 * @param {string[]} keys - string[] - An array of strings that are the keys of the cookies you want to
 * delete.
 */
const deleteCookie = (keys: string[]) => {
	keys.forEach((k) => {
		document.cookie = `${k}=; Path=/;expires=Thu, 01 Jan 1970 00:00:01 GMT; ${domainString}`;
	});
};

/**
 * Delete all cookies
 */
function deleteAllCookies() {
	// delete only main cookies
	const {
		AUTH_TOKEN_COOKIE,
		COURSE_ID_COOKIE,
		SESSION_ID_COOKIE,
		HIRATION_AUTH,
		ACADEMIC_PLANNER_ENABLED_COHORTS,
	} = cookies;
	deleteCookie([
		AUTH_TOKEN_COOKIE,
		COURSE_ID_COOKIE,
		SESSION_ID_COOKIE,
		HIRATION_AUTH,
		ACADEMIC_PLANNER_ENABLED_COHORTS,
	]);
}

export {
	setCookie,
	getCookie,
	deleteCookie,
	setCookieWithExpiry,
	deleteAllCookies,
};
