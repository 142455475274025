type Unit = 'lakh' | 'crore';

interface UnitMapping {
	[key: string]: number;
}

/**
 * It converts formatted string values to numbers
 * @param {value} value - value of type string
 * @returns number.
 */
export default function convertStringToNumber(value: string): number {
	const mappings: UnitMapping = {
		lakh: 100000,
		crore: 10000000,
	};
	const parts = value.split(' ');

	const numberPart = parseFloat(parts[0]);
	const unitPart = parts[1]?.toLowerCase() as Unit;

	if (unitPart && mappings[unitPart]) {
		return numberPart * mappings[unitPart];
	}

	return numberPart;
}
