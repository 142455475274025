import { useEffect } from 'react';

/**
 * "useDebounce() calls the callback function after a delay of 'delay' milliseconds, but only after the
 * dependencies have changed."
 *
 * The dependencies array is optional. If you don't pass it, the callback will be called only after the
 * delay has passed
 * @param callback - the function to be debounced.
 * @param {number} delay - The delay in milliseconds.
 * @param {any[]} dependencies - This is the array of dependencies that will be used to determine if
 * the callback should be called.
 */
export default function useDebounce(
	callback: () => void,
	delay: number,
	dependencies: any[],
) {
	useEffect(() => {
		const timerId = setTimeout(callback, delay);
		return () => {
			clearTimeout(timerId);
		};
		// eslint-disable-next-line
	}, [...dependencies]);
}
