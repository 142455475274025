// import { createAxios } from '../../utils/custom-axios';
import axios, { AxiosResponse } from 'axios';
import { getEnv } from 'utils/env';
import {
	User,
	LoginAPIRequestPayload,
	ForgotPasswordAPIRequestPayload,
	ChangePasswordAPIRequestPayload,
} from 'types/auth-services';

const unInterceptedAxios = axios.create();
const BASE_URL = getEnv('authApiServerUrl');
const URL = {
	LOGIN: `${BASE_URL}/auth/v5/login`,
	LOGOUT: `${BASE_URL}/logout`,
	FORGOT_PASSWORD: `${BASE_URL}/v2/users/password/forgot`,
	CHANGE_PASSWORD: `${BASE_URL}/v2/users/password/change`,
};

export default (() => {
	return {
		login: (userData: LoginAPIRequestPayload) =>
			unInterceptedAxios.post<AxiosResponse<User>>(URL.LOGIN, userData),
		logout: () => axios.post(URL.LOGOUT),
		forgotPassword: (payload: ForgotPasswordAPIRequestPayload) =>
			axios.post(URL.FORGOT_PASSWORD, payload),
		changePassword: (payload: ChangePasswordAPIRequestPayload) =>
			axios.post(URL.CHANGE_PASSWORD, payload),
	};
})();
