import { Navigate, Route, Routes } from 'react-router-dom';

// components
import PrivateRoute from './components/PrivateRoute';

import 'services/axios';

// styles
import './App.less';
import 'styles/index.scss';

import { Layout } from 'antd';
import { useOnMount } from 'hooks';
import { TrackEvents, Logger } from 'services';
import lazy from 'utils/lazy-load';

const Home = lazy(() => import('./pages/Home'));
const Login = lazy(() => import('./pages/Login'));
const Logout = lazy(() => import('./pages/Logout'));
const CohortSelection = lazy(() => import('./pages/CohortSelection'));
const InterviewPreparation = lazy(() => import('./pages/InterviewPreparation'));
const Profile = lazy(() => import('./pages/Profile'));
const ResumeBuilder = lazy(() => import('./pages/ResumeBuilder'));
const Jobs = lazy(() => import('./pages/Jobs'));
const AllJobs = lazy(() => import('./pages/AllJobs'));
const RecommendedJobs = lazy(() => import('./pages/RecommendedJobs'));
const SavedJobs = lazy(() => import('./pages/SavedJobs'));
const AppliedJobs = lazy(() => import('./pages/AppliedJobs'));
const JobDetails = lazy(() => import('./pages/JobDetails'));
const NotFound = lazy(() => import('./pages/NotFound'));

const logger = new Logger({ fileName: 'App' });

/**
 * The App component is the main component of the application. It renders the Layout component, which
 * in turn renders the Routes component. The Routes component renders the Home component, which in turn
 * renders the Jobs component. The Jobs component renders the AllJobs, RecommendedJobs, SavedJobs, and
 * AppliedJobs components
 * @returns The App component is being returned.
 */
function App() {
	useOnMount(() => {
		TrackEvents.log('c_entered_career_center', {
			category: 'General',
		});
		AllJobs.preload();
		RecommendedJobs.preload();
		SavedJobs.preload();
		AppliedJobs.preload();
	});

	logger.log('inside APP ======', process.env);
	return (
		<Layout style={{ minHeight: '100vh' }}>
			<Routes>
				<Route
					path="/"
					element={
						<PrivateRoute>
							<Home />
						</PrivateRoute>
					}
				>
					{/* <Route index element={<Jobs />} /> */}
					<Route index element={<Navigate to="jobs" replace />} />
					<Route path="jobs" element={<Jobs />}>
						<Route path="all" element={<AllJobs />} />
						<Route path="recommended" element={<RecommendedJobs />} />
						<Route path="saved" element={<SavedJobs />} />
						<Route path="applied" element={<AppliedJobs />} />
					</Route>
					<Route path="jobs/details/:jobId" element={<JobDetails />} />
					<Route path="preparation" element={<InterviewPreparation />}></Route>
					<Route path="profile" element={<Profile />}></Route>
				</Route>
				<Route
					path="courses"
					element={
						<PrivateRoute config={{ checkAuth: true, checkCohort: false }}>
							<CohortSelection />
						</PrivateRoute>
					}
				/>
				<Route path="resume-builder" element={<ResumeBuilder />}></Route>
				{/* <Route path="cohort" element={<CohortSelection />}></Route> */}
				<Route path="login" element={<Login />}></Route>
				<Route path="logout" element={<Logout />}></Route>
				<Route path="*" element={<NotFound />} />
			</Routes>
		</Layout>
	);
}

export default App;
