export enum JobType {
	ALL = 'all',
	APPLIED = 'applied',
	SAVED = 'saved',
	RECOMMENDED = 'recommended',
}

export enum SearchType {
	GENERAL = 'general',
	LOCATION = 'location',
}

export enum InternalJobType {
	INTERNSHIP = 'internship',
	FRESHER = 'fresher',
}
