import { notification } from 'antd';

export interface NotificationConfig {
	title?: string;
	description: string;
}

/**
 * It creates a notification of type success.
 * @param {NotificationConfig} config - It displays the success notification to the user
 */
export function success(config: NotificationConfig) {
	const { title: message = 'Success', description } = config;
	notification.success({ message, description });
}
/**
 * `notification.error` is a function that takes in a `NotificationConfig` object and displays an error
 * notification
 * @param {NotificationConfig} config - The error is displayed to the user
 */
export function error(config: NotificationConfig) {
	const { title: message = 'Something went wrong', description } = config;
	notification.error({ message, description });
}
/**
 * It displays a warning notification.
 * @param {NotificationConfig} config - The warning is displayed to the user
 */
export function warning(config: NotificationConfig) {
	const { title: message = 'Warning', description } = config;
	notification.warning({ message, description });
}
/**
 * It shows a notification with the title "Info" and the description.
 * @param {NotificationConfig} config - The info is displayed to the user
 */
export function info(config: NotificationConfig) {
	const { title: message = 'Info', description } = config;
	notification.info({ message, description });
}
const obj = { success, error, warning, info };
export default obj;
