import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';

import { Logger } from 'services';
import { getCookie } from '../../utils/cookies';

// constants
import { cookies } from './../../constants';

const { AUTH_TOKEN_COOKIE, COURSE_ID_COOKIE } = cookies;
const logger = new Logger({ fileName: 'PrivateRoute' });

interface PrivateRouteProps {
	children: JSX.Element;
	config?: { checkAuth: boolean; checkCohort: boolean };
}
const PrivateRoute: React.FC<PrivateRouteProps> = (props) => {
	// ********** props ***************
	const { config = { checkAuth: true, checkCohort: true }, children } = props;
	const { checkAuth, checkCohort } = config;

	// ********** hooks ***************
	const location = useLocation();

	// ********** variables ***************
	const authTokenCookieExists = !!getCookie(AUTH_TOKEN_COOKIE);
	const courseCookieExists = !!getCookie(COURSE_ID_COOKIE);

	/**
	 * This is checking if the user is logged in. If they are not logged in, then they are redirected to
	 * the `/login` page.
	 */
	if (checkAuth && !authTokenCookieExists) {
		logger.log('user not loggedIn');
		return <Navigate to="/login" state={{ from: location }} />;
	}

	/**
	 * This is checking if the user has a selected cohort. If they have no selected cohort,
	 * then they are redirected to the `/courses` page.
	 */
	if (checkCohort && !courseCookieExists) {
		logger.log('cohort not selected');
		// const externalCourseId = getCookie(EXTERNAL_COURSE_ID);
		// // check if courseId is present in cookie from learn platform
		// if (externalCourseId) {
		// 	setCookie({ [COURSE_ID_COOKIE]: externalCourseId });
		// } else {
		// if courseId is not present in any cookies then redirect to course selection page
		return <Navigate to="/courses" state={{ from: location }} />;
		// }
	}

	logger.log('user is loggedIn');
	return children;
};
export default PrivateRoute;
