export enum JobFiltersKeysEnum {
	// TODO: DEL-948: Disabling based on this ticket
	// EasyApply = 'easyApply',
	Internship = 'internship',
	FresherJob = 'fresherJob',
	Field = 'fields',
	Experience = 'experience',
	TotalExperience = 'experience.total',
	RelevantExperience = 'experience.relevant',
	Country = 'countries',
	Salary = 'salary',
	SalaryRange = 'salary.range',
	Location = 'locations',
	Company = 'companies',
	AppliedJobsSource = 'appliedJobsSource',
	SortBy = 'sortBy',
}

export enum FilerTypeEnum {
	RADIO = 'radio',
	CHECKBOX = 'checkbox',
	RANGE = 'range',
	TOGGLE = 'toggle',
}
