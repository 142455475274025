import { formatDistanceToNow, isFuture, format } from 'date-fns';
import { transform, isEqual, isArray, isObject } from 'lodash-es';

// utilities
import {
	CappingBreakpointsColor,
	CappingColor,
	JobFiltersKeysEnum,
	jobsConstants,
} from '../constants';

// types
import { LoginAPIErrorResponse } from 'types/auth-services';
import { DefaultOptionType } from 'antd/lib/select';
import {
	ApplicationCappingBreakpoint,
	ApplicationCappingResponseDto,
	JobsRequestFilterSortModel,
} from 'types/career-services';
import { Filters, TypedObject } from 'types/custom';
import { InternalJobType } from 'constants/job-type';
import { CurrencySalaryUnitMapping, CurrencyUnitSymbolMapping, SalaryTimeUnit } from 'constants/jobs-constants';
import { CurrencyUnitSymbolMappingCurrencies } from 'types/career-services/models/CurrencyUnitMappingModel';

/**
 * @description Message to show index of jobs based on selected page
 * @param {number} numberOfElements - jobs returned for the selected page from API
 * @param {number} currentPage - current page number returned from API
 * @param {number} totalElements - total elements found returned from API
 * @return {string} - message to show jobs number returned in the selected page
 */
export function jobsShowingText(
	numberOfElements: number,
	currentPage: number,
	totalElements: number,
) {
	const jobsToDeductFromRange = 0;
	const rangeBase =
		(currentPage === 0 ? 0 : currentPage - 1) * jobsConstants.pageSize;
	const listStart = rangeBase + 1;
	const listEnd = rangeBase + numberOfElements - jobsToDeductFromRange;
	return `Showing ${listStart} - ${listEnd} of ${totalElements} jobs`;
}

/**
 * Given the salaryFrom and salaryTo, return a string that represents the salary range
 * @param {number | undefined} salaryFrom - The minimum salary of the job.
 * @param {number | undefined} salaryTo - The maximum salary of the job
 * @returns a string that contains the salary range.
 */
export function getSalaryInString(
	salaryFrom: number | null,
	salaryTo: number | null,
	currency: string | null,
	salaryTimeUnit: string | undefined | null
) {
	if (!salaryFrom && !salaryTo) return 'NA';

	let currencyPrefix = currency ? (CurrencyUnitSymbolMapping[currency as CurrencyUnitSymbolMappingCurrencies] ?? `${currency} `) : '';
	let salarySuffix = '';
	let formattedSalaryFrom = null;
	let formattedSalaryTo = null;
	
	if(currency === CurrencyUnitSymbolMappingCurrencies.INR && (salaryTimeUnit == null || salaryTimeUnit === SalaryTimeUnit.Yearly)) {
		salarySuffix = ` ${CurrencySalaryUnitMapping[CurrencyUnitSymbolMappingCurrencies.INR]}`;
		if(salaryFrom != null) {
			formattedSalaryFrom = getFormattedSalaryInLPA(salaryFrom);
		}
		if(salaryTo != null) {
			formattedSalaryTo = getFormattedSalaryInLPA(salaryTo);
		}
	} else {
		salarySuffix = ` ${salaryTimeUnit ? salaryTimeUnit : SalaryTimeUnit.Yearly}`;
		if(salaryFrom != null) {
			formattedSalaryFrom = getFormattedSalary(salaryFrom, currency);
		}
		if(salaryTo != null) {
			formattedSalaryTo = getFormattedSalary(salaryTo,currency);
		}
	}

	if ((salaryFrom != null && salaryFrom > 0) && (salaryTo != null && salaryTo > 0)) {
		return `${
			salaryFrom === salaryTo
				? currencyPrefix + formattedSalaryFrom
				: currencyPrefix + formattedSalaryFrom + '-' + formattedSalaryTo
		}${salarySuffix}`;
	} else if (!salaryFrom && salaryTo) {
		return `Upto ${currencyPrefix}${formattedSalaryTo}${salarySuffix}`;
	} else if (!salaryTo && salaryFrom) {
		return `At least ${currencyPrefix}${formattedSalaryFrom}${salarySuffix}`;
	}
}

function getFormattedSalaryInLPA(salary: number) {
	return salary === 0 ? '0' : convertSalaryToLPA(salary);
}

function getFormattedSalary(salary: number, currency: string | null) {
	return salary === 0 ? '0' : checkSalaryDecimalPoints(salary, currency);
}

function convertSalaryToLPA(salary: number) {
	return checkSalaryDecimalPoints(salary / 100000.0, CurrencyUnitSymbolMappingCurrencies.INR);
}

function checkSalaryDecimalPoints(salary: number, currency: string | null) {
	salary = parseFloat(salary.toFixed(2));
	if(salary % 1 === 0) salary = Math.floor(salary);
	if(currency === CurrencyUnitSymbolMappingCurrencies.INR) return numberWithCommasIndianStandard(salary);
	else return numberWithCommas(salary);
}

function numberWithCommas(x: number) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

function numberWithCommasIndianStandard (x: number) {
	return x.toString().replace(/(\d)(?=(\d\d)+\d$)/g, "$1,");
}

/**
 * Given a minimum and maximum experience, return a string that describes the range of experience
 * @param {number | undefined} minExp - The minimum experience required for the job.
 * @param {number | undefined} maxExp - The maximum experience in years.
 * @param {boolean} [extraShort=false] - Value when min and max are the same
 * @returns A string.
 */
export function getExperienceInString(
	minExp: number | undefined,
	maxExp: number | undefined,
	extraShort: boolean = false,
) {
	if (extraShort) return `${minExp}-${maxExp} yr`;
	if (minExp && maxExp) {
		return minExp === maxExp ? `${minExp} years` : `${minExp}-${maxExp} years`;
	} else if (!minExp && maxExp) {
		return `upto ${maxExp} years`;
	} else if (minExp && !maxExp) {
		return `at least ${minExp} years`;
	} else {
		return 'NA';
	}
}

/**
 * Given the number of applicants, return a string that says how many applicants there are
 * @param {number} applicantsCount - The number of applicants for the job,
 * @param {boolean} isMobile - To check if the device is mobile
 * @returns A string that is either "Apply first" or "Be the first to apply"
 */
export function getApplicantsCountInString(
	applicantsCount: number,
	isMobile: boolean,
) {
	if (applicantsCount === 0) {
		return isMobile ? 'Apply first' : 'Be the first to apply';
	} else {
		return `${applicantsCount} Applied`;
	}
}

/**
 * If the date is in the future, return a string with the date and time. If the date is in the past,
 * return a string with the time since the date
 * @param {Date | number} date - The date of the job
 * @param {string} prefixType - The date in string
 * @returns A string.
 */
export function parseToDateFormat(
	date: Date | number,
	prefixType: string,
): string {
	if (isFuture(date)) {
		return (
			'Interview on <b>' +
			format(date, 'MMM D') +
			' at ' +
			format(date, 'h:mm A') +
			'</b>'
		);
	} else {
		if (prefixType === 'interview') {
			return 'Interviewed ' + formatDistanceToNow(date) + ' ago';
		} else {
			return 'Saved ' + formatDistanceToNow(date) + ' ago';
		}
	}
}
/**
 * Given a number of bytes, format it into a human-readable string
 * @param {number} bytes - The number of bytes to be converted.
 * @param {number} [decimals=0] - The number of decimals to display. Defaults to 0.
 * @returns A string.
 */
export function formatBytes(bytes: number, decimals: number = 0) {
	if (bytes === 0) return '0 Bytes';
	const k = 1000,
		dm = decimals <= 0 ? 0 : decimals || 2,
		sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'],
		i = Math.floor(Math.log(bytes) / Math.log(k));
	return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
}
/**
 * It returns the error message if the response is not successful and there is an error message.
 * @param {LoginAPIErrorResponse} data - The data returned from the API call.
 * @returns The error message if there is one, otherwise null.
 */

export function retriveErrorMessage(data: LoginAPIErrorResponse) {
	if (!data.success && data.errorList?.length) {
		return data.errorList[0].errorMessage
			? data.errorList[0].errorMessage
			: 'Login Unsuccessful';
	}
	return null;
}

export function filtersEventString(
	data: JobsRequestFilterSortModel,
	schema: Filters,
): string {
	function getLabel(key: string, childKey?: string) {
		let rangeValues = schema.find((jobFilter) => jobFilter.key === key);

		if (childKey) {
			rangeValues = rangeValues?.children?.find(
				(jobChildFilter) => jobChildFilter.key === childKey,
			);
		}

		if (rangeValues && 'min' in rangeValues && 'max' in rangeValues) {
			return [rangeValues?.min.label, rangeValues.max.label];
		}
		return ['0', '30+'];
	}

	const eventString = `easyApply-${data.filters?.easyApply},fresherJob-${
		data.filters?.fresherJob
	},internship-${
		data.filters?.internship
	},Domain-{${data.filters?.fields?.toString()}},experience_relevant:${
		data.filters?.experience?.relevant?.min ??
		getLabel(
			JobFiltersKeysEnum.Experience,
			JobFiltersKeysEnum.RelevantExperience,
		)[0]
	}to${
		data.filters?.experience?.relevant?.max ??
		getLabel(
			JobFiltersKeysEnum.Experience,
			JobFiltersKeysEnum.RelevantExperience,
		)[1]
	},experience_total:${
		data.filters?.experience?.total?.min ??
		getLabel(
			JobFiltersKeysEnum.Experience,
			JobFiltersKeysEnum.TotalExperience,
		)[0]
	}to${
		data.filters?.experience?.total?.max ??
		getLabel(
			JobFiltersKeysEnum.Experience,
			JobFiltersKeysEnum.TotalExperience,
		)[1]
	},salary:${
		data.filters?.salary?.range.min ??
		getLabel(JobFiltersKeysEnum.Salary, JobFiltersKeysEnum.Salary)[0]
	}to${
		data.filters?.salary?.range.max ??
		getLabel(JobFiltersKeysEnum.Salary, JobFiltersKeysEnum.Salary)[1]
	},location-{${data.filters?.locations?.toString()}}`;
	return eventString;
}

/**
 * Generate a random string of a given length
 * @param {number} length - The length of the string to be generated.
 * @param [chars=0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ] - The characters to be
 * used in the random string.
 * @returns A string of random characters.
 */
export function getRandomString(
	length: number,
	chars = '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ',
) {
	let result = '';
	for (let i = length; i > 0; --i)
		result += chars[Math.floor(Math.random() * chars.length)];
	return result;
}

/**
 * sorts the options so that the keyword typed by the user is listed last
 * @param {DefaultOptionType} optionA - DefaultOptionType - The first option to compare
 * @param {DefaultOptionType} optionB - DefaultOptionType - The second option to compare.
 * @param {string} searchString - string - The third option to compare first and second with.
 * @returns a number.
 */
export function searchOptionSort(
	optionA: DefaultOptionType,
	optionB: DefaultOptionType,
	searchString: string | null,
) {
	if (optionA.children?.toString() === searchString) {
		return 1;
	}
	if (optionB.children?.toString() === searchString) {
		return -1;
	}
	return 0;
}

/**
 * It takes an object and returns a new object with all the nested objects flattened
 * @param obj - The object to flatten
 * @param [prefix] - This is the prefix that will be added to the key of the nested object.
 * @returns flattened object
 * @example
 * const obj = {
 * 	name: 'varun',
 * 		meta: {
 * 			address: { country: 'India', state: 'M.P', city: 'satna' },
 * 				phone: '12345667',
 * 				alias: ['name1', 'name2'],
 * 		},
 * 	};
 * output is:-
 * { name: 'varun',
 *	'meta.address.country': 'India',
 *	'meta.address.state': 'M.P',
 *	'meta.address.city': 'satna',
 *	'meta.phone': '12345667',
 *	'meta.alias': [ 'name1', 'name2' ] }
 */
export const flattenObject = (obj: TypedObject<any>, prefix = '') => {
	const newObj = Object.keys(obj).reduce((acc: TypedObject<any>, key) => {
		const currentProp = obj[key];
		const newKey = prefix ? `${prefix}.${key}` : key;
		if (typeof currentProp === 'object' && !Array.isArray(currentProp)) {
			const nestedObj = flattenObject(currentProp, newKey);
			acc = { ...acc, ...nestedObj };
		} else {
			acc[newKey] = obj[key];
		}
		return acc;
	}, {});

	return newObj;
};

/**
 * Find difference between two objects
 * @param  {object} origObj - Source object to compare newObj against
 * @param  {object} newObj  - New object with potential changes
 * @return {object} differences
 */
export function differenceObj(
	origObj: TypedObject<any>,
	newObj: TypedObject<any>,
) {
	function changes(origObj: TypedObject<any>, newObj: TypedObject<any>) {
		let arrayIndexCounter = 0;
		return transform(newObj, function (result: TypedObject<any>, value, key) {
			if (!isEqual(value, origObj[key])) {
				let resultKey = isArray(origObj) ? arrayIndexCounter++ : key;
				result[resultKey] =
					isObject(value) && isObject(origObj[key])
						? changes(value, origObj[key])
						: value;
			}
		});
	}
	return changes(newObj, origObj);
}
/**
 * It takes an application capping object and returns a capping color object based on the percentage and breakpoints
 * @param {ApplicationCapping} applicationCapping - ApplicationCapping
 * @returns An object with two properties: chip and icon.
 */
export function getCappingColor(
	applicationCapping: ApplicationCappingResponseDto,
): CappingColor {
	const { percentageApplied = 0, breakpoints = [] } = applicationCapping;
	const breakpointsArr: number[] = [];
	const FINAL_PERCENTAGE = 100;

	breakpoints.forEach((breakpoints) => {
		breakpoints.breakpoint && breakpointsArr.push(breakpoints.breakpoint);
	});

	switch (true) {
		case percentageApplied >= FINAL_PERCENTAGE:
			return CappingBreakpointsColor[CappingBreakpointsColor.length - 1];
		case breakpointsArr.length &&
			percentageApplied < FINAL_PERCENTAGE &&
			percentageApplied >= breakpointsArr[0]:
			const index = [0, ...breakpointsArr, 100].findIndex(
				(breakpoint) => percentageApplied < breakpoint,
			);
			return CappingBreakpointsColor[index - 1];
		default:
			return CappingBreakpointsColor[0];
	}
}

/**
 * It returns a message to be displayed to the user when they are about to exceed their application
 * limit
 * @param {ApplicationCappingResponseDto | null} applicationCapping - ApplicationCappingResponseDto |
 * null
 * @param {boolean} isMobile - boolean - This is a boolean value that tells us if the user is on mobile
 * or not.
 * @returns An object with two properties: primaryText and secondaryText.
 */
export function applicationCapNotificationMessage(
	applicationCapping: ApplicationCappingResponseDto | null,
	isMobile: boolean,
): {
	primaryText: string;
	secondaryText: string;
} {
	const breakpoint: ApplicationCappingBreakpoint | undefined =
		applicationCapping?.breakpoints &&
		applicationCapping?.breakpoints.find(
			(breakpointObj) =>
				breakpointObj.count &&
				breakpointObj.count - 1 === applicationCapping.appliedCount,
		);
	const text = {
		primaryText: '',
		secondaryText: '',
	};
	if (
		breakpoint &&
		applicationCapping?.applicationCappingLimit &&
		breakpoint.count
	) {
		text.primaryText = `You are going to exhaust ${breakpoint.breakpoint}% of the allowed applications this month`;
		text.secondaryText = isMobile
			? ` You have ${
					applicationCapping.applicationCappingLimit - breakpoint.count
			  } applications left for ${capitalizeFirstLetter(
					applicationCapping.currentMonth,
			  )}. Do you want to proceed?`
			: `This is your ${breakpoint.count}th application this month. You have ${
					applicationCapping.applicationCappingLimit - breakpoint.count
			  } applications left for ${capitalizeFirstLetter(
					applicationCapping.currentMonth,
			  )}. Do you want to proceed?`;
	}
	if (
		!breakpoint &&
		applicationCapping?.applicationCappingLimit &&
		applicationCapping?.appliedCount ===
			applicationCapping.applicationCappingLimit - 1
	) {
		text.primaryText = 'Heads up!  This is your last application';
		text.secondaryText = isMobile
			? `You are going to exhaust the maximum application quota for ${capitalizeFirstLetter(
					applicationCapping.currentMonth,
			  )}.
		Do you still want to proceed?`
			: `<div>You are going to exhaust the maximum application quota for ${capitalizeFirstLetter(
					applicationCapping.currentMonth,
			  )}.</div>
		<div>Do you still want to proceed?<div>`;
	}
	return text;
}

export const shortListingText = (
	jobType: string | undefined,
	jobDomains: string[] | undefined,
	relevantExperienceString?: string,
) => {
	if (jobType?.toLowerCase().includes(InternalJobType.FRESHER)) {
		return `This job accepts applications from learners with no prior experience
				in <b>${jobDomains?.join(', ')}</b>.`;
	} else if (jobType?.toLowerCase().includes(InternalJobType.INTERNSHIP)) {
		return `This is an internship opportunity in
				<b>${jobDomains?.join(', ')}</b>.`;
	} else {
		return `To increase your chances of shortlisting in this job you need to
				have <b>${relevantExperienceString}</b> of experience in
				<b>${jobDomains?.join(', ')}</b>.`;
	}
};

export function capitalizeFirstLetter(value: string = '') : string {
	return value.toLowerCase().replace(/(^\w{1})|(\s+\w{1})/g, letter => letter.toUpperCase());
}
