import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import reportWebVitals from './reportWebVitals';
import { StoreProvider } from 'easy-peasy';

import App from './App';
import Spinner from 'components/Spinner';

import store from './store';

ReactDOM.render(
	<Suspense fallback={<Spinner />}>
		<React.StrictMode>
			<StoreProvider store={store}>
				<BrowserRouter>
					<App />
				</BrowserRouter>
			</StoreProvider>
		</React.StrictMode>
	</Suspense>,
	document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
