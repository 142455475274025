import { Grid } from 'antd';
import { DeviceType } from '../constants';

const { useBreakpoint } = Grid;

const useResponsive = () => {
	const screens = useBreakpoint();
	const deviceType = getDeviceType();

	/**
	 * If the screen size is greater than or equal to 1200 pixels wide, return "desktop". If the screen
	 * size is greater than or equal to 992 pixels wide but less than 1200 pixels wide, return "tablet".
	 * Otherwise, return "mobile"
	 * @returns The device type.
	 */
	function getDeviceType() {
		if (screens.lg) return DeviceType.DESKTOP;
		if (screens.md) return DeviceType.TABLET;
		return DeviceType.MOBILE;
	}

	return {
		deviceType,
		isDesktop: deviceType === DeviceType.DESKTOP,
		isTablet: deviceType === DeviceType.TABLET,
		isMobile: deviceType === DeviceType.MOBILE,
		screens,
	};
};
export default useResponsive;
