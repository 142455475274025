import { action, Action, thunk, Thunk, thunkOn, ThunkOn } from 'easy-peasy';
import { set as lodashSet } from 'lodash-es';

import {
	BookMarkModel,
	JobFiltersModel,
	RelevanceModel,
	HasAppliedModel,
	LocationRecentSearchPayload,
	LocationModel,
	ApplicationCappingResponseDto,
} from 'types/career-services';
import {
	ApplyJobPayload,
	MappedJobDetails,
	MappedJobDetailsUpdate,
	ResumeList,
	TypedObject,
	ScrollPosition,
	UserProfessionalDetails,
} from 'types/custom';

import allJobs, { AllJobsStore } from './all-jobs';
import appliedJobs, { AppliedJobsStore } from './applied-jobs';
import recommendedJobs, { RecommendedJobsStore } from './recommended-jobs';
import savedJobs, { SavedJobsStore } from './saved-jobs';
import { StoreModel } from 'store';

import api from './service';
import { JobType, JobFiltersKeysEnum } from '../../constants';
import { TrackEvents } from 'services';
import { DEFAULT_SCROLL_POSITIONS } from './data';
import extractUserProfessionalDetails from '../../utils/extractUserProfessionalDetails';

export interface JobsStore {
	// ********** Sub Stores ***************
	all: AllJobsStore;
	recommended: RecommendedJobsStore;
	saved: SavedJobsStore;
	applied: AppliedJobsStore;
	// ********** Data ***************
	externalOpenedJobs: TypedObject<boolean>;
	filterOptions: JobFiltersModel | null;
	jobDetails: MappedJobDetails | null;
	resumeList: ResumeList | null;
	userProfessionalDetails: UserProfessionalDetails | null;
	recentLocationSearches: string[];
	isProfileFitVisible: boolean;
	applicationCapping: ApplicationCappingResponseDto | null;
	scrollPosition: ScrollPosition;
	selectedTab: JobType;
	// ********** Actions ***************
	reset: Action<JobsStore>;
	setFilterOptions: Action<JobsStore, JobFiltersModel>;
	setJobDetails: Action<JobsStore, MappedJobDetails>;
	resetJobDetails: Action<JobsStore>;
	setResumeList: Action<JobsStore, ResumeList>;
	setUserProfessionalDetails: Action<JobsStore, UserProfessionalDetails>;
	setIsProfileFitVisible: Action<JobsStore, boolean>;
	updateJobDetails: Action<JobsStore, MappedJobDetailsUpdate>;
	addToExternalOpenedJobs: Action<JobsStore, { jobId: number }>;
	removeFromExternalOpenedJobs: Action<JobsStore, { jobId: number }>;
	setRecentLocationSearches: Action<JobsStore, string[]>;
	setApplicationCapping: Action<JobsStore, ApplicationCappingResponseDto>;
	resetApplicationCapping: Action<JobsStore>;
	setScrollPosition: Action<JobsStore, Partial<ScrollPosition>>;
	resetScrollPosition: Action<JobsStore>;
	setSelectedTab: Action<JobsStore, JobType>;
	// ********** Thunks ***************
	getAllFilterOptions: Thunk<JobsStore, undefined>;
	getJobDetails: Thunk<JobsStore, { jobId: string }>;
	getResumes: Thunk<JobsStore, number>;
	getUserProfessionalDetails: Thunk<JobsStore, number>;
	checkProfileFitVisibility: Thunk<JobsStore>;
	validateApplicationCapping: Thunk<JobsStore>;
	applyJob: Thunk<JobsStore, { jobId: number; apiPayload: ApplyJobPayload }>;
	updateRelevance: Thunk<
		JobsStore,
		{ jobId: number; jobType: JobType; payload: RelevanceModel }
	>;
	updateBookmark: Thunk<
		JobsStore,
		{ jobId: number; jobType: JobType; payload: BookMarkModel }
	>;
	fetchClickedOutside: Thunk<
		JobsStore,
		{ jobId: number; payload: HasAppliedModel }
	>;
	fetchAppliedOutside: Thunk<
		JobsStore,
		{ jobId: number; payload: HasAppliedModel }
	>;
	fetchRecentLocationSearches: Thunk<JobsStore, undefined>;
	fetchLocationSuggestions: Thunk<JobsStore, string>;
	fetchLocations: Thunk<JobsStore, string[], null, StoreModel>;
	updateRecentLocationSearch: Thunk<JobsStore, LocationRecentSearchPayload>;
	// ********** Listeners ***************
	onSetCCLaunchData: ThunkOn<JobsStore, any, StoreModel>;
	onUserReset: ThunkOn<JobsStore, any, StoreModel>;
	onSetFeatureFlags: ThunkOn<JobsStore, any, StoreModel>;
	onUpdateFilters: ThunkOn<JobsStore, any, StoreModel>;
}

const jobs: JobsStore = {
	// ********** Sub Stores ***************
	all: allJobs,
	recommended: recommendedJobs,
	saved: savedJobs,
	applied: appliedJobs,
	// ********** Data ***************
	jobDetails: null,
	resumeList: null,
	userProfessionalDetails: null,
	filterOptions: null,
	externalOpenedJobs: {},
	recentLocationSearches: [],
	isProfileFitVisible: false,
	applicationCapping: null,
	scrollPosition: DEFAULT_SCROLL_POSITIONS,
	selectedTab: JobType.ALL,
	// ********** Actions ***************
	reset: action((state, payload) => {
		state.jobDetails = null;
		state.resumeList = null;
		state.filterOptions = null;
		state.userProfessionalDetails = null;
		state.externalOpenedJobs = {};
		state.isProfileFitVisible = false;
		state.applicationCapping = null;
		state.scrollPosition = DEFAULT_SCROLL_POSITIONS;
	}),
	setJobDetails: action((state, payload) => {
		state.jobDetails = payload;
	}),
	resetJobDetails: action((state, payload) => {
		state.jobDetails = null;
	}),
	updateJobDetails: action((state, payload) => {
		const { toChange, jobId } = payload;
		const jobDetails = state.jobDetails;
		if (jobDetails?.id === jobId) {
			toChange.forEach(({ key, value }) => {
				lodashSet(jobDetails, key, value);
			});
			state.jobDetails = jobDetails;
		}
	}),
	setResumeList: action((state, payload) => {
		state.resumeList = payload;
	}),
	setUserProfessionalDetails: action((state, payload) => {
		state.userProfessionalDetails = payload;
	}),
	setIsProfileFitVisible: action((state, payload) => {
		state.isProfileFitVisible = payload;
	}),
	setFilterOptions: action((state, payload) => {
		state.filterOptions = payload;
	}),
	addToExternalOpenedJobs: action((state, payload) => {
		const updatedExternalOpenedJobs = {
			...state.externalOpenedJobs,
			[payload.jobId]: true,
		};
		state.externalOpenedJobs = updatedExternalOpenedJobs;
	}),
	removeFromExternalOpenedJobs: action((state, payload) => {
		const updatedExternalOpenedJobs = {
			...state.externalOpenedJobs,
		};
		delete updatedExternalOpenedJobs[payload.jobId];
		state.externalOpenedJobs = updatedExternalOpenedJobs;
	}),
	setRecentLocationSearches: action((state, payload) => {
		state.recentLocationSearches = payload;
	}),
	setApplicationCapping: action((state, payload) => {
		state.applicationCapping = payload;
	}),
	resetApplicationCapping: action((state) => {
		state.applicationCapping = null;
	}),
	setScrollPosition: action((state, payload) => {
		state.scrollPosition = { ...state.scrollPosition, ...payload };
	}),
	resetScrollPosition: action((state) => {
		state.scrollPosition = DEFAULT_SCROLL_POSITIONS;
	}),
	setSelectedTab: action((state, payload) => {
		state.selectedTab = payload;
	}),
	// ********** Thunks ***************
	getJobDetails: thunk(async (actions, payload, { fail }) => {
		const { jobId } = payload;
		try {
			const jobDetails = await api.fetchCompleteJobDetails(+jobId);
			actions.setJobDetails(jobDetails);
		} catch (error) {
			fail(error);
		}
	}),
	getResumes: thunk(async (actions, payload) => {
		const response = await api.fetchResumeList(payload);
		actions.setResumeList(response);
	}),
	getUserProfessionalDetails: thunk(async (actions, payload) => {
		const [professionalDetails, locationDetails] = await Promise.allSettled([
			api.fetchUserProfessionalDetails(payload),
			api.fetchUserLocationDetails(payload),
		]);
		const professionalDetailsFeedbacks: any =
			professionalDetails.status === 'fulfilled'
				? professionalDetails.value?.feedbacks
				: [];
		const locationDetailsFeedbacks: any =
			locationDetails.status === 'fulfilled'
				? locationDetails.value?.feedbacks
				: [];
		const result = extractUserProfessionalDetails([
			...professionalDetailsFeedbacks,
			...locationDetailsFeedbacks,
		]);
		actions.setUserProfessionalDetails(result);
	}),
	checkProfileFitVisibility: thunk(async (actions) => {
		const hasUserAppliedFewJobs = await api.checkProfileFitVisibility();
		const isProfileFitVisible = !hasUserAppliedFewJobs;
		actions.setIsProfileFitVisible(isProfileFitVisible);
	}),
	validateApplicationCapping: thunk(async (actions) => {
		const response = await api.validateApplicationCapping();
		response.data && actions.setApplicationCapping(response.data);
	}),
	applyJob: thunk(async (actions, payload) => {
		const { jobId, apiPayload } = payload;
		try {
			await api.applyJob(jobId, apiPayload);
			actions.updateJobDetails({
				jobId,
				toChange: [{ key: 'applicationStatus', value: 'Applied' }],
			});
		} catch (err: any) {
			TrackEvents.log('c_job_apply_error', {
				job_id: jobId,
				job_type: 'CEIPAL',
				error_message: err?.message,
			});
		}
	}),
	getAllFilterOptions: thunk(async (actions, payload) => {
		const { filterList, locationsList, countriesList } =
			await api.fetchAllFilterOptions();
		filterList.countries = countriesList;
		const temp: Record<string, string[]> = {};
		locationsList.forEach((countryLocationMapping) => {
			temp[countryLocationMapping.countryName] =
				countryLocationMapping.locations;
		});
		filterList.locations = temp;
		actions.setFilterOptions(filterList);
	}),
	updateBookmark: thunk(
		async (actions, { jobId, payload }, { fail, injections }) => {
			const { api } = injections;

			try {
				const response = await api.jobs.updateBookmark(jobId, payload);

				if (response.errorCode) {
					return fail(response.message);
				}

				actions.updateJobDetails({
					jobId,
					toChange: [
						{ key: 'userAction.isBookMarked', value: !!payload.value },
					],
				});
				return response;
			} catch (err) {
				console.log('update Bookmark error', err);
			}
		},
	),
	updateRelevance: thunk(async (actions, { jobId, payload }, { fail }) => {
		try {
			const response = await api.updateRelevance(jobId, payload);

			if (response.errorCode) {
				return fail(response.message);
			}

			actions.updateJobDetails({
				jobId,
				toChange: [
					{ key: 'userAction.relevance', value: payload.relevance },
					{
						key: 'userAction.relevanceReasonId',
						value: payload.relevanceReason,
					},
				],
			});
			return response;
		} catch (err) {
			console.log('update Relevance error', err);
		}
	}),
	fetchClickedOutside: thunk(
		async (actions, { jobId, payload }, { getState }) => {
			await api.fetchClickedOutside(jobId, payload);
		},
	),
	fetchAppliedOutside: thunk(
		async (actions, { jobId, payload }, { getState }) => {
			await api.fetchAppliedOutside(jobId, payload);
		},
	),
	fetchRecentLocationSearches: thunk(async (actions) => {
		const response = await api.fetchRecentLocationSearches();

		if (response) {
			actions.setRecentLocationSearches(response);
		}
	}),
	updateRecentLocationSearch: thunk(async (actions, payload) => {
		return api.updateRecentLocationSearch(payload);
	}),
	fetchLocationSuggestions: thunk(async (actions, payload) => {
		const response = await api.fetchLocationSuggestions(payload);
		if (Array.isArray(response)) {
			const cities = response.map((location: LocationModel) => location.city);
			return cities;
		}
	}),
	fetchLocations: thunk(async (actions, payload, { getState }) => {
		const response = await api.fetchLocations(payload);
		const filterOptions = getState().filterOptions as JobFiltersModel;
		const temp: Record<string, string[]> = {};
		response.forEach((countryLocationMapping) => {
			temp[countryLocationMapping.countryName] =
				countryLocationMapping.locations;
		});
		filterOptions.locations = temp;
		actions.setFilterOptions(filterOptions);
	}),
	// ********** Listeners ***************
	onSetCCLaunchData: thunkOn(
		(actions, storeActions) => storeActions.learnerController.setLaunchData,
		async (actions, target) => {
			await actions.getAllFilterOptions();
			const { payload } = target;
			const { data } = payload;
			if (data && data.user && data.user.id) {
				actions.getResumes(data.user.id);
				actions.getUserProfessionalDetails(data.user.id);
			}
		},
	),

	onSetFeatureFlags: thunkOn(
		(actions, storeActions) => storeActions.learnerController.setFeatureFlags,
		async (actions, target) => {
			await actions.validateApplicationCapping();
		},
	),

	onUserReset: thunkOn(
		(actions, storeActions) => storeActions.user.reset,
		async (actions, target) => {
			await actions.reset();
		},
	),

	onUpdateFilters: thunkOn(
		(actions, storeActions) => [
			storeActions.jobs.all.updateFilters,
			storeActions.jobs.recommended.updateFilters,
		],
		async (actions, target) => {
			const { key, value } = target?.payload;
			if (key === JobFiltersKeysEnum.Country) {
				actions.fetchLocations(value);
			}
		},
	),
};

export default jobs;
