import {
	action,
	Action,
	Thunk,
	thunk,
	ThunkOn,
	thunkOn,
	ActionOn,
	actionOn,
} from 'easy-peasy';
import { set as lodashSet } from 'lodash-es';
// types
import {
	JobMetadata,
	JobsRequestFilterSortModel,
	JobsRequestPaginationModel,
	PageElasticJobs,
	UserJobActionModel,
} from 'types/career-services';
import {
	Filters,
	ElasticMappedJobs as MappedJobs,
	ElasticMappedJob as MappedJob,
	ElasticMappedJobUpdateInput as MappedJobUpdateInput,
	LabelValuePair,
} from 'types/custom';

import api, { formatLocationsFilterData } from '../service';
import { StoreModel } from 'store/index';
import { DEFAULT_QUERY_PAYLOAD, FILTERS_DATA } from './data';
import { JobFiltersKeysEnum, JobType } from 'constants/index';
import { ArrayToObj } from 'utils/mapper';
import { capitalizeFirstLetter } from 'utils/helpers';

export interface RecommendedJobsStore {
	// ********** Data ***************
	data: PageElasticJobs | null;
	filterSchemas: Filters;
	queryPayload: JobsRequestFilterSortModel;
	mappedJobs: MappedJobs | null;
	loading: boolean;

	// ********** Actions ***************
	reset: Action<RecommendedJobsStore>;
	setData: Action<RecommendedJobsStore, PageElasticJobs>;
	setQueryPayload: Action<RecommendedJobsStore, JobsRequestFilterSortModel>;
	resetQueryPayload: Action<RecommendedJobsStore>;
	updateFilters: Action<RecommendedJobsStore, { key: string; value: any }>;
	setMappedJobs: Action<RecommendedJobsStore, MappedJobs>;
	setSortBy: Action<RecommendedJobsStore, string>;
	setPagination: Action<RecommendedJobsStore, JobsRequestPaginationModel>;
	resetPagination: Action<RecommendedJobsStore>;
	resetSalary: Action<RecommendedJobsStore>;
	toggleLoading: Action<RecommendedJobsStore, boolean>;
	updateMappedJob: Action<RecommendedJobsStore, MappedJobUpdateInput>;
	deleteMappedJob: Action<RecommendedJobsStore, { jobId: number }>;
	setLocationSearchKeywords: Action<RecommendedJobsStore, string[]>;
	resetLocationSearchKeywords: Action<RecommendedJobsStore>;
	// ********** Thunks ***************
	fetchRecommendedJobs: Thunk<RecommendedJobsStore, undefined>;
	fetchJobsMetaAndActions: Thunk<RecommendedJobsStore, { jobIds: number[] }>;

	// ********** Listeners ***************
	onUserReset: ThunkOn<RecommendedJobsStore, any, StoreModel>;
	onSetData: ThunkOn<RecommendedJobsStore, any, StoreModel>;
	onSetFilterOptions: ActionOn<RecommendedJobsStore, StoreModel>;
	onUpdateBookmarkSuccess: ThunkOn<RecommendedJobsStore, any, StoreModel>;
	onUpdateRelevanceSuccess: ThunkOn<RecommendedJobsStore, any, StoreModel>;
}

const recommendedJobs: RecommendedJobsStore = {
	// ********** Data ***************
	data: null,
	filterSchemas: FILTERS_DATA,
	queryPayload: DEFAULT_QUERY_PAYLOAD,
	mappedJobs: null,
	loading: false,
	// ********** Actions ***************
	reset: action((state, payload) => {
		state.data = null;
		state.queryPayload = DEFAULT_QUERY_PAYLOAD;
		state.mappedJobs = null;
		state.loading = false;
	}),
	setData: action((state, payload) => {
		state.mappedJobs = null; // to reset mappedJobs to show latest request jobs and not previous requested jobs
		state.data = payload;
	}),
	setQueryPayload: action((state, payload) => {
		state.queryPayload = payload;
	}),
	resetQueryPayload: action((state, payload) => {
		state.queryPayload = DEFAULT_QUERY_PAYLOAD;
	}),
	updateFilters: action((state, payload) => {
		const { key, value } = payload;
		let filters: any = state.queryPayload.filters;
		lodashSet(filters, key, value);
		const pagination = DEFAULT_QUERY_PAYLOAD.pagination;
		state.queryPayload = { ...state.queryPayload, pagination, filters };
	}),
	setSortBy: action((state, payload) => {
		state.queryPayload.sortBy = payload;
	}),
	setPagination: action((state, payload) => {
		state.queryPayload.pagination = payload;
	}),
	resetPagination: action((state, payload) => {
		state.queryPayload.pagination = DEFAULT_QUERY_PAYLOAD.pagination;
	}),
	resetSalary: action((state, payload) => {
		state.queryPayload.salaryMinset = false;
		state.queryPayload.salaryMaxset = false;
		if (state.queryPayload.filters?.salary?.range.min) state.queryPayload.filters.salary.range.min = undefined;
		if (state.queryPayload.filters?.salary?.range.max) state.queryPayload.filters.salary.range.max = undefined;
	}),
	updateMappedJob: action((state, payload) => {
		const { toChange } = payload;
		const updatedMappedJobs = state.mappedJobs || [];
		const mappedJobIndex = updatedMappedJobs.findIndex(
			(mappedJob: MappedJob) => mappedJob.jobId === payload.jobId,
		);

		if (mappedJobIndex > -1) {
			toChange.forEach(({ key, value }) => {
				lodashSet(updatedMappedJobs[mappedJobIndex], key, value);
			});
			state.mappedJobs = updatedMappedJobs;
		}
	}),
	deleteMappedJob: action((state, payload) => {
		if (state.mappedJobs) {
			state.mappedJobs = state.mappedJobs?.filter(
				(mappedJob: MappedJob) => mappedJob.id !== payload.jobId,
			);
		}
	}),
	setMappedJobs: action((state, payload) => {
		state.mappedJobs = payload;
	}),
	toggleLoading: action((state, payload) => {
		state.loading = payload;
	}),
	setLocationSearchKeywords: action((state, payload) => {
		state.queryPayload.locationSearchKeywords = payload;
	}),
	resetLocationSearchKeywords: action((state, payload) => {
		state.queryPayload.locationSearchKeywords = DEFAULT_QUERY_PAYLOAD.locationSearchKeywords;
	}),
	// ********** Thunks ***************
	fetchRecommendedJobs: thunk(async (actions, payload, { getState }) => {
		// Loading Intializing
		actions.toggleLoading(true);

		const recommendedJobsPayload = getState().queryPayload;
		const response = await api.fetchRecommendedJobs(recommendedJobsPayload);

		actions.setData(response);
		actions.setMappedJobs(response.content || []);
		actions.toggleLoading(false);
	}),
	fetchJobsMetaAndActions: thunk(async (actions, payload, { getState }) => {
		const { metaList, userActionList } = await api.fetchMetaAndActions(
			payload.jobIds,
		);

		// check if there are any metadata and actions to be mapped
		if (!metaList.length && !userActionList.length) {
			return;
		}

		const metaMap = ArrayToObj(metaList, 'jobId') as {
			[key: number]: JobMetadata;
		};
		const actionsMap = ArrayToObj(userActionList, 'jobId') as {
			[key: number]: UserJobActionModel;
		};

		const jobs = getState().data?.content || [];
		const mappedJobs = jobs.map((job) => {
			const jobId = job.id as number;
			// return { ...job, meta:metaMap[jobId], userAction:actionsMap[jobId] };
			return { ...job, ...metaMap[jobId], ...actionsMap[jobId] };
		});
		actions.setMappedJobs(mappedJobs);
	}),

	// ********** Listeners ***************
	onUserReset: thunkOn(
		(actions, storeActions) => storeActions.user.reset,
		async (actions, target) => {
			await actions.reset();
		},
	),
	onSetData: thunkOn(
		(actions) => actions.setData,
		async (actions, target) => {
			const jobs = target?.payload?.content || [];
			// using as keyword because we know job.id can never be undefined
			const jobIds = jobs.map((job) => job.id) as number[];
			jobIds.length && actions.fetchJobsMetaAndActions({ jobIds });
		},
	),
	onSetFilterOptions: actionOn(
		(actions, storeActions) => storeActions.jobs.setFilterOptions,
		(state, target) => {
			const { payload: filterOptions } = target;

			let updatedFilterSchema = state.filterSchemas.map((filterObj) => {

				if (filterObj.key === JobFiltersKeysEnum.Field) {
					if ('options' in filterObj) {
						let options = filterOptions?.fields || [];
						const mappedOptions: LabelValuePair<string>[] = [];
						options.forEach((option) => {
							if (option['filterKey'] && option['filterText']) {
								mappedOptions.push({
									label: capitalizeFirstLetter(option['filterText']),
									value: option['filterKey']
								});
							}
						});
						filterObj.options = mappedOptions;
					}
				} else if (filterObj.key === JobFiltersKeysEnum.Location) {
					if ('options' in filterObj) {
						const mappedOptions: LabelValuePair<string>[] = [];
						let locationOptions: string[] = [];
						if(Object.keys(filterOptions?.locations ?? {}).length) {
							locationOptions = formatLocationsFilterData(filterOptions.locations ?? {});
						}
						locationOptions.forEach((location: string) => {
							mappedOptions.push({
								label: capitalizeFirstLetter(location),
								value: location
							})
						});
						filterObj.options = mappedOptions;
					}
				} else if (filterObj.key === JobFiltersKeysEnum.Country) {
					if ('options' in filterObj) {
						const mappedOptions: LabelValuePair<string>[] = [];
						filterOptions.countries?.forEach((option: any) => {
							mappedOptions.push({
								label: capitalizeFirstLetter(option.filterText),
								value: option.filterKey
							})
						});
						filterObj.options = mappedOptions;
					}
				}

				return filterObj;
			});

			state.filterSchemas = updatedFilterSchema;
		},
	),
	onUpdateBookmarkSuccess: thunkOn(
		(actions, storeActions) => storeActions.jobs.updateBookmark.successType,
		async (actions, target) => {
			const { payload } = target;
			if (payload.jobType === JobType.RECOMMENDED) {
				actions.updateMappedJob({
					jobId: payload.jobId,
					toChange: [{ key: 'isBookMarked', value: payload.payload.value }],
				});
			}
		},
	),
	onUpdateRelevanceSuccess: thunkOn(
		(actions, storeActions) => storeActions.jobs.updateRelevance.successType,
		async (actions, target) => {
			const { payload } = target;
			if (payload.jobType === JobType.RECOMMENDED) {
				actions.updateMappedJob({
					jobId: payload.jobId,
					toChange: [
						{ key: 'relevance', value: payload.payload.relevance },
						{
							key: 'relevanceReasonId',
							value: payload.payload.relevanceReason,
						},
					],
				});
			}
		},
	),
};

export default recommendedJobs;
